import React, { useState, useEffect, useMemo } from 'react';
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
//import { HelmetProvider } from "react-helmet-async"
import AuthPage, { auth } from './pages/authpage';
import { User } from 'firebase/auth';
import { Routes, Route } from 'react-router-dom';
import MainPage from './pages/main';
import SubjectPage from './pages/subjectpage';
import NotFoundPage from './pages/404';
import MenuPage from './pages/menu';
import { AuthProvider } from "./contexts/AuthContext"
import Admin from './pages/admin';
import { addAppUserOnLogin, getAppUser } from './serverlib/db';
import { AppUser } from './serverlib/util-types';
//FONT FIX SIMPLE
import "@fontsource/poppins";
import './styles/mainpage-styles.css';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
interface AppProps {
    user: User | null;
}
function App(props: AppProps) {
    localStorage.setItem("deviceLanguage", navigator.language.split("-")[0]);
    var [user, setUser] = useState<User | null>(props.user);
    const [loading, setLoading] = useState(true);
    var [appUser, setAppUser] = useState<AppUser | null>(null);
    useMemo(() => {
        if (user !== null) {
            getAppUser(user?.uid!).then(AppUserResult => {
                if (AppUserResult !== null) {
                    setAppUser(AppUserResult as AppUser);
                    setLoading(false)
                }
            });
        }
    }, [user]);
    console.log("appUser name : ", appUser?.name);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setUser(user);
            setLoading(false);
        });
        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (user === null) return;
        if (appUser !== null) {
            console.log("AppUser found in state. (beginning of useEffect)");
            setLoading(false);
            return () => { console.log("return and break useeffect. (end of useEffect)"); };
        };
        if (user.email?.endsWith("@sj.k12.tr")) {
            console.log("User is a sj member.");
            addAppUserOnLogin(auth.currentUser!.uid!, auth.currentUser?.providerData[0]?.displayName!, auth.currentUser?.providerData[0]?.email!, "student").then((au) => {
                if (au as AppUser) {
                    setAppUser(au as AppUser);
                    setLoading(false);
                }
                else console.log("Error while adding/getting appUser");
            });

        } else {
            console.log("User is not a sj member.");
            // REMOVE BEFORE PRODUCTION
            addAppUserOnLogin(auth.currentUser!.uid!, auth.currentUser?.providerData[0]?.displayName!, auth.currentUser?.providerData[0]?.email!, "student").then((au) => {
                console.log("(REMOVE BEFORE PROD) But still added user to db.");
                if (au as AppUser) {
                    setAppUser(au as AppUser);
                    setLoading(false);
                }
                else console.log("Error while adding/getting appUser");
            });

        }
    }, [user, appUser, loading]);
    //LOADING PAGE, AUTHPAGE DOESNT SHOW ANYMORE.
    //loading doesnt update, so it is always true. fix it.
    if (loading) { // component waits for an async action, loading screen required.
        console.log("loading final : ", loading)
        return (
            <div
                style={{ color: "white", alignContent: "center", alignItems: "center", textAlign: "center", justifyContent: "center", display: "flex", height: "100vh", width: "100vw", fontSize: "24px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" height="200px" width="200px" viewBox="0 0 200 200" className="pencil">
                    <defs>
                        <clipPath id="pencil-eraser">
                            <rect height="30" width="30" ry="5" rx="5"></rect>
                        </clipPath>
                    </defs>
                    <circle transform="rotate(-113,100,100)" stroke-linecap="round" stroke-dashoffset="439.82" stroke-dasharray="439.82 439.82" stroke-width="2" stroke="currentColor" fill="none" r="70" className="pencil__stroke"></circle>
                    <g transform="translate(100,100)" className="pencil__rotate">
                        <g fill="none">
                            <circle transform="rotate(-90)" stroke-dashoffset="402" stroke-dasharray="402.12 402.12" stroke-width="30" stroke="hsl(223,90%,50%)" r="64" className="pencil__body1"></circle>
                            <circle transform="rotate(-90)" stroke-dashoffset="465" stroke-dasharray="464.96 464.96" stroke-width="10" stroke="hsl(223,90%,60%)" r="74" className="pencil__body2"></circle>
                            <circle transform="rotate(-90)" stroke-dashoffset="339" stroke-dasharray="339.29 339.29" stroke-width="10" stroke="hsl(223,90%,40%)" r="54" className="pencil__body3"></circle>
                        </g>
                        <g transform="rotate(-90) translate(49,0)" className="pencil__eraser">
                            <g className="pencil__eraser-skew">
                                <rect height="30" width="30" ry="5" rx="5" fill="hsl(223,90%,70%)"></rect>
                                <rect clip-path="url(#pencil-eraser)" height="30" width="5" fill="hsl(223,90%,60%)"></rect>
                                <rect height="20" width="30" fill="hsl(223,10%,90%)"></rect>
                                <rect height="20" width="15" fill="hsl(223,10%,70%)"></rect>
                                <rect height="20" width="5" fill="hsl(223,10%,80%)"></rect>
                                <rect height="2" width="30" y="6" fill="hsla(223,10%,10%,0.2)"></rect>
                                <rect height="2" width="30" y="13" fill="hsla(223,10%,10%,0.2)"></rect>
                            </g>
                        </g>
                        <g transform="rotate(-90) translate(49,-30)" className="pencil__point">
                            <polygon points="15 0,30 30,0 30" fill="hsl(33,90%,70%)"></polygon>
                            <polygon points="15 0,6 30,0 30" fill="hsl(33,90%,50%)"></polygon>
                            <polygon points="15 0,20 10,10 10" fill="hsl(223,10%,10%)"></polygon>
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
    if (user) {


        return (
            user ?
                <AuthProvider>
                    <Routes>
                        <Route path='/*' element={<NotFoundPage />} />
                        <Route path='/' element={<MainPage user={user} />} />
                        <Route path='/menu' element={<MenuPage user={user} />} />
                        {/* <Route path='/authpage' element={<AuthPage user={user} />} /> */}
                        {/* <Route path='/adminlogin' element={<AdminLogin/>} /> */}
                        <Route path='/admin' element={<Admin user={user} subjectfromurl={null} gradefromurl={null} actualgradefromurlforsubject={null} defaultTopicFromTextMatTopic={null} />} />
                        <Route path={'/subjectpage/*'} element={<SubjectPage user={user} appUser={appUser} />} />
                        {/* <Route path='/texteditor' element={<TextEditor user={user} subjectfromurl={null} gradefromurl={null}  */}
                        {/* textMatTopic={null} textMatTopicUnit={null} textMatTopicSubtopic1={null}  />} /> */}
                    </Routes>
                </AuthProvider>
                :
                <AuthProvider>
                    <AuthPage user={user} />
                </AuthProvider>
        );
    }
    else { console.log("bö"); return <AuthProvider><AuthPage user={user} /></AuthProvider> }
}

export default App;
