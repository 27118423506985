import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
const firebaseConfig = {
    apiKey: "AIzaSyBPxcpKyOYsWraEW_Nw3cRwMjZrPQMqofI",
    authDomain: "metaclass.dens.dev", //sjapp1.firebaseapp.com, 
    databaseURL: "https://sjapp1-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "sjapp1",
    storageBucket: "sjapp1.appspot.com",
    messagingSenderId: "1085001691243",
    appId: "1:1085001691243:web:de9d2eed58640b9db7812e",
    measurementId: "G-ZTZS54ERY8"
};

const app = initializeApp(firebaseConfig)
const auth = getAuth()
const analytics = getAnalytics(app);
analytics.app.automaticDataCollectionEnabled = true;

export {auth,app,firebaseConfig};
