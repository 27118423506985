import { useEffect, useState } from "react";
import { Topic , AppUser , Subject} from "../serverlib/util-types";
import { getTopics, addTopic, editTopic, delTopic , getAppUser , getSubjects, addSubtopic} from "../serverlib/db";
import { User } from "firebase/auth";
import { useTranslation } from "react-i18next";

//icons
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
interface TopicPageProps {
    user: User | null;
    subjectfromurl : Subject | null;
    gradefromurl : string | null ;
}

const TopicsPage = (props: TopicPageProps) => {
    const { t } = useTranslation("common");

    const [availableSubjects, setAvailableSubjects] = useState<string[] |null>([]);
    const [appUser, setAppUser] = useState<AppUser | null>(null);
    const [subject, setSubject] = useState<Subject | ''>(props.subjectfromurl as Subject); // Initialize subject state with empty string
    const [grade, setGrade] = useState("");

    //KOPYAMAKARNA

    const [topics, setTopics] = useState<string[]>([]);
    const [subtopics, setSubtopics] = useState<{ [topic: string]: string[] }>({});
    const [newTopic, setNewTopic] = useState('');
    const [newSubtopic, setNewSubtopic] = useState('');
    const [subtopicInputs, setSubtopicInputs] = useState<{ [topic: string]: string }>({});

    /*TOPICCCCCCCSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS */

    const [editingInfo, setEditingInfo] = useState<{ topic: string | null, newName: string | null }>({ topic: null, newName: null });
    const [editingSubtopicInfo, setEditingSubtopicInfo] = useState<{ topic: string | null, index: number | null, newName: string | null }>({ topic: null, index: null, newName: null });

    const getExistingTopics = async () => {
        try {
            console.log("subject topic: ", subject);
            console.log("grade topic: ", grade);
            const existingTopics = await getTopics(subject as Subject, "subjects"+grade);
            setTopics(existingTopics.map((topic) => topic.tname));
            setSubtopics(existingTopics.reduce((acc: { [topic: string]: string[] }, topic) => {
                acc[topic.tname] = topic.subtopics;
                return acc;
            }, {}));
        } catch (error) {
            console.error("Error getting existing topics:", error);
        }
    };
    useEffect(() => {
        if (grade !== '' && subject !== '') {
            getExistingTopics();
        }
    }, [grade, subject, props.gradefromurl, props.subjectfromurl, props.user]);

    const handleAddTopic = async () => {
        if (newTopic.trim() !== '') {
            try {
                const topicId = await addTopic(subject as Subject, grade, newTopic);
                if (topicId !== null) {
                    setTopics([...topics, newTopic]);
                    setSubtopics({ ...subtopics, [newTopic]: [] });
                    setNewTopic('');
                    console.log("added topicId: ", topicId);
                }
            } catch (error) {
                console.error("Error adding topic:", error);
            }
        }
    };

    const handleAddSubtopic = async (topic: string) => {
        const newSubtopicValue = subtopicInputs[topic].trim();

        if (newSubtopicValue !== '') {
            setSubtopics({
                ...subtopics,
                [topic]: [...(subtopics[topic] || []), newSubtopicValue],
            });
            setSubtopicInputs({ ...subtopicInputs, [topic]: '' });

            // Update the subtopics in Firestore
            try {
                const resAddSubTopic = await addSubtopic(subject as Subject, grade, topic, newSubtopicValue);
                if (resAddSubTopic !== null) {
                    console.log("res: ", resAddSubTopic);
                }
            } catch (error) {
                console.error("Error updating subtopics:", error);
            }
        }
    };

    const handleDeleteTopic = async (topic: string) => {
        // Delete the topic from Firestore
        try {
            await delTopic(subject as Subject, grade, topic);
        } catch (error) {
            console.error("Error deleting topic:", error);
        }

        // Update the state
        const updatedTopics = [...topics].filter((t) => t !== topic);
        const updatedSubtopics = { ...subtopics };
        delete updatedSubtopics[topic];

        setTopics(updatedTopics);
        setSubtopics(updatedSubtopics);
    };

    const handleDeleteSubtopic = async (topic: string, index: number) => {
        const updatedSubtopics = { ...subtopics };
        updatedSubtopics[topic].splice(index, 1);

        setSubtopics(updatedSubtopics);

        // Update the subtopics in Firestore
        try {
            await editTopic(subject as Subject, grade, topic, topic, updatedSubtopics[topic]);
        } catch (error) {
            console.error("Error updating subtopics:", error);
        }
    };

    const handleEditTopic = (topic: string) => {
        setEditingInfo({ topic, newName: topic });
    };

    const handleSaveTopic = async (topic: string, newName: string) => {
        setEditingInfo({ topic: null, newName: null });
        if (newName !== topic) {
            const updatedTopics = topics.map((t) => (t === topic ? newName : t));
            const updatedSubtopics = { ...subtopics };
            updatedSubtopics[newName] = updatedSubtopics[topic];
            delete updatedSubtopics[topic];

            setTopics(updatedTopics);
            setSubtopics(updatedSubtopics);

            // Update the topic in Firestore
            try {
                await editTopic(subject as Subject, grade, topic, newName, updatedSubtopics[newName]);
            } catch (error) {
                console.error("Error updating topic:", error);
            }
        }
    };

    const handleEditSubtopic = (topic: string, subtopicIndex: number) => {
        setEditingSubtopicInfo({ topic, index: subtopicIndex, newName: subtopics[topic][subtopicIndex] });
    };

    const handleSaveSubtopic = async (topic: string, index: number, newName: string) => {
        setEditingSubtopicInfo({ topic: null, index: null, newName: null });
        if (newName !== subtopics[topic][index]) {
            const updatedSubtopics = { ...subtopics };
            updatedSubtopics[topic][index] = newName;

            setSubtopics(updatedSubtopics);

            // Update the subtopics in Firestore
            try {
                await editTopic(subject as Subject, grade, topic, topic, updatedSubtopics[topic]);
            } catch (error) {
                console.error("Error updating subtopics:", error);
            }
        }
    };

    // const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, topic: string, index: number | null, isTopic: boolean) => {
    //     if (event.key === 'Enter') {
    //         event.preventDefault();
    //         if (isTopic) {
    //             if (topics.includes(newTopic)) {
    //                 handleSaveTopic(topic, editingInfo.newName || '');
    //             } else {
    //             handleAddTopic();
    //             }
    //         } else {
    //             if (subtopics[topic].includes(newSubtopic)) {
    //                 handleSaveSubtopic(topic, index || 0, editingSubtopicInfo.newName || '');
    //             } else {
    //             handleAddSubtopic(topic);
    //             }
    //             // handleSaveSubtopic(topic, index || 0, editingSubtopicInfo.newName || '');
    //         }
    //     }
    // };

    const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>, topic: string, index: number | null, isTopic: boolean) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (isTopic) {
                if (topics.includes(newTopic)) {
                    handleSaveTopic(topic, editingInfo.newName || '');
                } else {
                    await handleAddTopic();
                }
            } else {
                if (subtopics[topic].includes(newSubtopic)) {
                    handleSaveSubtopic(topic, index || 0, editingSubtopicInfo.newName || '');
                } else {
                    await handleAddSubtopic(topic);
                }
            }
        }
    };

    /*TOPICCCCCCCSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS end*/

    var gradeint = String(grade.match(/\d+/g));
    if (gradeint === "null") {
        gradeint = "Prep";
    }
    const getAvailableSubjects = async () => {
        //use firestore to get available subjects
        if (gradeint === "null") {
            gradeint = props.gradefromurl as string;
        }
        console.log("gradeint TOPICS: ", gradeint);
        console.log("props.gradefromurl TOPICS: ", props.gradefromurl);
        // await getSubjects(gradeint).then((subjects) => setAvailableSubjects(subjects as string[]));
        const result = require(`../data/subjectsgeneralized.json`);
        const subjects = result[gradeint as string];
        setAvailableSubjects(subjects as string[]);
        if (availableSubjects !== null)
        console.log("availableSubjects: ", availableSubjects as string[]);
        
    }

    useEffect(() => {
        getAvailableSubjects();
    }, [gradeint, props.gradefromurl, props.subjectfromurl, props.user]);

    useEffect(() => {
        getTopics(props.gradefromurl! as Subject, props.subjectfromurl!).then(
            (topics) => ((setTopics(topics.map((topic) => (topic.tname))), console.log("topics: ", topics)), setSubtopics(topics.reduce((acc: { [topic: string]: string[] }, topic) => {
                acc[topic.tname] = topic.subtopics;
                return acc;
            }, {})))
        );
    }, [props.gradefromurl, props.subjectfromurl]);

    useEffect(() => {
        getAppUser(props.user?.uid!).then((AppUser) => setAppUser(AppUser));
    }, [props.user]);

    useEffect(() => {
        if (props.subjectfromurl !== null && props.gradefromurl !== null) {
            setSubject(props.subjectfromurl);
            setGrade(props.gradefromurl);
            // setGradeForAlert(gradefromurl);
            // setSubjectForAlert(subjectfromurl);
        }
    }, [props.subjectfromurl, props.gradefromurl]);

    const [isTopicsContentVisible, setIsTopicsContentVisible] = useState(false);
    const handleToggleTopicsContent = () => {
        setIsTopicsContentVisible(!isTopicsContentVisible);
    };

    if (appUser && (appUser.isAdmin || appUser?.accesslevel === "teacher") && (props.subjectfromurl !== null && props.gradefromurl !== null)) {
    return(
        <>
            <div className="admin-widget">
                <div className="admin-widget-title" onClick={handleToggleTopicsContent}>
                    <b>📂 {t("admin.add-topic")}</b>
                </div>

                <div className={`admin-widget-content ${isTopicsContentVisible ? 'visible' : ''}`}>
                    <hr />
                    <div className="admin-subwidget">
                        <b>{t("admin.topics")}</b>
                        {/* {(props.subjectfromurl === null && props.gradefromurl === null) && 
                            <> */}
                                <div className="admin-input-wrapper">
                                    <label htmlFor="class"><b>{t("admin.grade")}</b></label>
                                    <select className="admin-dropdown" name="class" id="class-select" required defaultValue={props.gradefromurl as string}
                                        onChange={(e) => setGrade(e.target.value)}>
                                        <option value="Prep">{t("subjectpage.prep")}</option>
                                        <option value="9">9{t("subjectpage.thgrader")}</option>
                                        <option value="10">10{t("subjectpage.thgrader")}</option>
                                        <option value="11">11{t("subjectpage.thgrader")}</option>
                                        <option value="12">12{t("subjectpage.thgrader")}</option>
                                    </select>
                                </div>
                                <div className="admin-input-wrapper">
                                    <label htmlFor="subject"><b>{t("admin.subject")}</b></label>
                                    {grade !== '' &&
                                        <select className="admin-dropdown" name="subject" id="subject-select" required defaultValue={props.subjectfromurl as string}
                                            onChange={(e) => (setSubject(e.target.value as Subject), console.log(e.target.value))}>
                                            {availableSubjects && availableSubjects.map((subject) => (
                                                <option key={subject} value={subject.replace(/\s/g, '').toLowerCase()}>
                                                    {subject}
                                                </option>
                                            ))}
                                        </select>}
                                </div>
                            {/* </>
                        } */}
                        

                        <div className="subtopics-wrapper">
                            {Object.keys(subtopics).map((topic: string) => (
                                // map all topics as input fields (help)

                                <div key={topic} className="topic-item">
                                    {editingInfo.topic === topic ? (
                                        <input
                                            className="admin-input"
                                            type="text"
                                            value={editingInfo.newName || ''}
                                            onChange={(e) => setEditingInfo({ ...editingInfo, newName: e.target.value })}
                                            onBlur={() => handleSaveTopic(topic, editingInfo.newName || '')}
                                            onKeyDown={(e) => handleKeyDown(e, topic, null, true)}
                                        /> 
                                    ) : (
                                        <span onClick={() => handleEditTopic(topic)}>
                                            ✨ <b style={{ color: "var(--accent-color)" }}>{topic}</b><EditIcon />
                                        </span>
                                    )}

                                    <div className="subtopics-wrapper">
                                        {subtopics[topic].map((subtopic: string, index: number) => (
                                            // map all subtopics as input fields (help)
                                            <div key={index} className="subtopic-item">
                                                {editingSubtopicInfo.topic === topic && editingSubtopicInfo.index === index ? (
                                                    <input
                                                        className="admin-input"
                                                        type="text"
                                                        value={editingSubtopicInfo.newName || ''}
                                                        onChange={(e) => setEditingSubtopicInfo({ ...editingSubtopicInfo, newName: e.target.value })}
                                                        onBlur={() => handleSaveSubtopic(topic, editingSubtopicInfo.index || 0, editingSubtopicInfo.newName || '')}
                                                        onKeyDown={(e) => handleKeyDown(e, topic, editingSubtopicInfo.index, false)}
                                                    />
                                                ) : (
                                                    <span onClick={() => handleEditSubtopic(topic, index)}>{subtopic}</span>
                                                )}
                                                <button className="admin-delete-button" onClick={() => handleDeleteSubtopic(topic, index)}>
                                                    <DeleteIcon />
                                                </button>
                                            </div>
                                        ))}

                                        <div className="admin-input-wrapper">
                                            <input
                                                className="admin-input"
                                                type="text"
                                                name="subtopic"
                                                value={subtopicInputs[topic] || ''}
                                                onChange={(e) => setSubtopicInputs({ ...subtopicInputs, [topic]: e.target.value })}
                                                onKeyDown={(e) => e.key === 'Enter' && handleAddSubtopic(topic)}
                                                placeholder={t("admin.newtopic")}
                                            />
                                            <button className="add-button hover-up" onClick={() => handleAddSubtopic(topic)}>
                                                &nbsp;+&nbsp;
                                            </button>
                                        </div>
                                    </div>
                                    <button className="admin-delete-button" onClick={() => handleDeleteTopic(topic)}>
                                        <DeleteIcon />
                                    </button>
                                </div>
                            ))}

                            <div className="admin-input-wrapper">
                                <input
                                    className="admin-input"
                                    type="text"
                                    name="topic"
                                    value={newTopic}
                                    onChange={(e) => setNewTopic(e.target.value)}
                                    onKeyDown={(e) => e.key === 'Enter' && handleAddTopic()}
                                    placeholder={t("admin.newtopicunit")}
                                />
                                <button className="add-button hover-up" onClick={handleAddTopic}>
                                    &nbsp;+&nbsp;
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
                        
        </>
    )
    } else {
        return null;
    }
    
}

export default TopicsPage;