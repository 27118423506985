// import { User } from 'firebase/auth';
import {Timestamp} from 'firebase/firestore';

export enum Subject {
    math = "math",
    bio = "bio",
    phy = "phy",
    chem = "chem",
}

export class Material {
    name: string;
    content: string;
    topic: string;
    materialtype: string;
    time: Timestamp;
    visible: boolean;
    teacherinfo: string[]; // [name, mail, uid] //change to docref
    
    constructor(name: string, content: string, teacherinfo: string[], materialtype: string, topic: string, timestamp: Timestamp, visible: boolean = true) {
        this.name = name
        this.content = content
        this.topic = topic
        this.materialtype = materialtype
        this.time = timestamp;
        this.visible = visible
        this.teacherinfo = teacherinfo

    }
}

export class Alert {
  author: string;
  importance: number;
  message: string;
  title: string;
  time: Timestamp;
  lessonandgrade?: string;
  
  constructor(author:string, importance:number, message:string , title :string, lessonandgrade?:string) {
    this.author = author;
    this.importance = importance;
    this.message = message;
    this.title = title;
    this.lessonandgrade = lessonandgrade;
    this.time = Timestamp.now();
  }

}

export class Topic {
    tname: string;
    subject: Subject;
    grade: string;
    materials: Material[];
    time: Timestamp;
    subtopics: string[];
    // index: number;

    constructor(tname: string, subject: Subject, grade: string, materials: Material[]) {
        this.tname = tname;
        this.subject = subject;
        this.grade = grade;
        this.materials = materials;
        this.subtopics = [];
        this.time = Timestamp.now();
        // this.index = index;
    }
}

export class AppUser {
    uid: string;
    name: string;
    mail: string;
    accesslevel: string;
    isAdmin: boolean;
    creationTime: Timestamp;

    constructor(uid: string, name: string, mail: string, accesslevel: string, isAdmin: boolean) {
        this.uid = uid;
        this.name = name;
        this.mail = mail;
        this.accesslevel = accesslevel;
        this.isAdmin = isAdmin;
        this.creationTime = Timestamp.now();

    }
}

export class Teacher extends AppUser{
    grades: string[]
    subjects: string[]
    materials: Material[]

    constructor(appuser: AppUser, grades: string[], subjects:string[], materials: Material[], isAdmin: boolean) {
        if (appuser) {
            super(appuser.uid, appuser.name, appuser.mail, "teacher", isAdmin);
            this.grades = grades
            this.materials = materials
            this.subjects = subjects
        } else {
            throw new Error("AppUser is null")
        }
        
        
    }
}
//Never gonna give you up
//Never gonna let you down
//Never gonna run around and desert you
//Never gonna make you cry
//Never gonna say goodbye
//Never gonna tell a lie and hurt you
