import React, { useEffect, useState } from "react";
import { Material } from "../serverlib/util-types";
import { useTranslation } from "react-i18next";
import "../styles/subject-page.css";
// create a search bar that searcheses "materials" prop and if theres a match in the content, topic, or name it shows the designated material as a summary div under it that redirects to the content of the material.
interface SearchQueryMaterialsProps {
    materials: Material[];
}
export const SearchQueryMaterials = (props: SearchQueryMaterialsProps) => {
    const { t } = useTranslation("common");
    const [search, setSearch] = useState("");
    const [searchResultsDiv, setSearchResultsDiv] = useState<string[]>([]);
    useEffect(() => {
        var results: string[] = [];
        props.materials && props.materials.forEach(material => {
            if (search && ((material.name.toLowerCase().includes(search.toLowerCase()) || material.topic?.toLowerCase().includes(search.toLowerCase())) && material.materialtype !== "text" )) { //|| material.content.toLowerCase().includes(search.toLowerCase())
                results.push(
                    `
                    <div class="material-search-result">
                        <a href="${material.content}">
                            <iframe src="${material.content}"></iframe>
                            <span>
                                <p class="material-search-result-title">${material.name} </p>  
                                <p class="material-search-result-topic">${material.topic === "/" ? t('subjectpage.mainpage') : `${material.topic.split("/")[0]}` +"/"+ (material.topic.split("/")[1].length !== 0 ? material.topic.split("/")[1] : t('subjectpage.sub-general'))}</p>
                            </span>
                        </a>
                    </div>`
                );
            } else {
                console.log("no match");
                console.log(results);
                setSearchResultsDiv([]);
            }
        });
        setSearchResultsDiv(results);
    }, [search, props.materials]);
    return (
        <div className="materials-search">
            <input className="admin-input" type="text" placeholder={t("misc.search-ph")} onChange={(e) => setSearch(e.target.value)} />
            <div className="material-search-results">
                {searchResultsDiv && searchResultsDiv.map((div, index) => {
                    console.log(div);
                    return (
                        <div key={index} dangerouslySetInnerHTML={{ __html: div }}></div>
                    )
                })}
                {/* if no results, return error paragraph */}
                {search && searchResultsDiv.length === 0 && <p>{t("misc.nomatch")}</p>}
            </div>
        </div>
    )
}