import React from "react";
// import { useRouteError } from "react-router-dom";
import "../styles/error404.css";
import "../styles/mainpage-styles.css";
import HomeIcon from '@mui/icons-material/Home';

const handleGoToRoot = () => {
    // Change window location to the root path
    window.location.href = '/';
};

function NotFoundPage() {
    return (
        <div className="body-404 page-wrapper" style={{ margin: 0 }}>

            <div className="text-404">404</div>
            <p className="description-404">
                <b>Girdiğiniz adres yanlış veya sayfa silinmiş. &nbsp;&nbsp; </b> <a href="https://forms.gle/1dXApjbXShd4mSZD8"><b><u>Bildir</u></b></a><br />
                <i>L'adresse que vous avez saisie est incorrecte ou la page a été supprimée. &nbsp;&nbsp;</i> <a href="https://forms.gle/1dXApjbXShd4mSZD8"><b><u>Signaler</u></b></a><br />
                <br />
            </p>
            
            <b className="description-404-secondary">
                Okulun nasıl gizli koridorları varsa bu sitenin de var. <br />
                Buraya nasıl geldiğini bilmiyoruz, ama sessizce alttaki tuşa basarsan kimsenin bundan haberi olmaz. 😉 <br />
            </b>
            <br />
            <button className="generic-button hover-up colored-button" onClick={handleGoToRoot}>
                <HomeIcon />Ana Sayfa / Menu Principal
            </button>
            <br />
            <i className="description-404-secondary">
                Comme l’école dispose de couloirs secrets, ce site en possède également. <br />
                Nous ne savons pas comment vous êtes arrivé ici, mais si vous juste appuyez sur le bouton ci-dessus, personne ne le saura. 😉 <br />
            </i>

        </div>
    );
}

export default NotFoundPage;
