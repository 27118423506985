import React, { useContext, createContext, useEffect, useState, ReactNode } from 'react';
import {
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
  User,
  signInWithPopup,
  getAdditionalUserInfo,
  // getRedirectResult
} from 'firebase/auth';
import { auth } from '../configs/firebaseconfigs';
import { addAppUserOnLogin } from "../serverlib/db";
export interface AuthContextType {
    user: User | null;
    googleSignIn: () => void;
    logOut: () => void;
    isAuthenticated: boolean;
}
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);

    const googleSignIn = () => {
        const provider = new GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/userinfo.email');
        provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
        // provider.addScope('https://www.googleapis.com/auth/classroom.courses.readonly');
        // provider.addScope('https://www.googleapis.com/auth/classroom.coursework.me');
        provider.addScope('https://www.googleapis.com/auth/drive.file');
        provider.addScope('openid');
        provider.setCustomParameters({
          'login_hint': 'adsoyad@sj.k12.tr',
          // 'hd': 'sj.k12.tr'
        });
        //if a new user is being created, run the function "addAppUserOnLogin" in db.ts
        signInWithPopup(auth, provider).then((result) => { 
          console.log("access token: ", GoogleAuthProvider.credentialFromResult(result)?.accessToken);
          if (getAdditionalUserInfo(result)!.isNewUser) {
            console.log("User is new (auth) (authContext)");
            if (auth.currentUser?.providerData[0].email?.endsWith("@sj.k12.tr")) {
              addAppUserOnLogin(auth.currentUser!.uid!, auth.currentUser?.providerData[0]?.displayName!, auth.currentUser?.providerData[0]?.email!, "student");
            } else {
              console.log("User is not a sj member. (auth) (authContext)");
              //remove before production
              addAppUserOnLogin(auth.currentUser!.uid!, auth.currentUser?.providerData[0]?.displayName!, auth.currentUser?.providerData[0]?.email!, "student");
              console.log("(REMOVE BEFORE PROD) But still added user to db. (auth) (authContext)");
            }
          } else {
            console.log("User already exists (auth) (authContext)");
          }
        });
    
};

const logOut = () => {
    signOut(auth);
};

useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      console.log('User', currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

const contextValue: AuthContextType = {
    user,
    googleSignIn,
    logOut,
    isAuthenticated: user !== null,
};

return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
