import React, { useRef, useState, useEffect } from "react";
import { User } from "firebase/auth";
import { Editor } from '@tinymce/tinymce-react';
import SendIcon from '@mui/icons-material/Send';
import { Editor as TinyMCEEditor } from 'tinymce';
import { addMaterial, delMaterial, editMaterial, db, getAppUser } from "../serverlib/db";
import { collection, getDocs, Timestamp } from "firebase/firestore";
import { uploadFile } from "../serverlib/storage";
import { Material, Subject, Topic, AppUser } from "../serverlib/util-types";
import { useTranslation } from "react-i18next";
import "../styles/editorCustomStyles.css";
import "../styles/admin.css";



interface TextEditorProps {
    user: User | null;
    subjectfromurl: Subject | null;
    gradefromurl: string | null;
    //general topic, finalized:
    textMatTopic: string | null;
    textMatTopicUnit: string | null;
    textMatTopicSubtopic1: string | null;
}

const TextEditor = (props: TextEditorProps) => {
    const user = props.user;
    const { t } = useTranslation("common");
    var [appUser, setAppUser] = useState<AppUser | null>(null);
    var [content, setContent] = useState<string | undefined>('');
    // text materialType ı ile ve belirli (oluşturulan veya seçilen) bir topic altına material ekleme yapılacak
    // const [topic, setTopic] = useState("")
    const [materialType, setMaterialType] = useState("text")
    const [materialName, setMaterialName] = useState("");
    const [subject, setSubject] = useState<Subject | ''>(''); // Initialize subject state with empty string
    const [grade, setGrade] = useState("");
    const [teacherinfo, setTeacherInfo] = useState<string[]>([]);
    //for tinyMCE
    const editorRef = useRef<TinyMCEEditor | null>(null);

    //text material topic

    // const [textMatTopicUnit, setTextMatTopicUnit] = useState("");
    // const [textMatTopicSubtopic1, setTextMatTopicSubtopic1] = useState("");

    const [topic, setTopic] = useState<string>(props.textMatTopic || "");

    const [textTypeMaterial, setTextTypeMaterial] = useState<any[]>([]);
    const [editorInitialized, setEditorInitialized] = useState(false);

    const handleEditorInit = () => {
        setEditorInitialized(true);
    };
    useEffect(() => {
        if (editorInitialized) {
            handleGetTextTypeMaterial();
        }
    }, [editorInitialized, topic, subject, grade]);

    const [showTextMatConfirmationElement, setShowTextMatConfirmationElement] = useState(false);
    const [showTextMatUpdateConfirmationElement, setShowTextMatUpdateConfirmationElement] = useState(false);
    //get AppUser
    useEffect(() => {
        getAppUser(props.user?.uid!).then(AppUser => {
            if (AppUser !== null) {
                setAppUser(AppUser as AppUser);
                console.log("user accesslevel(s) : ", AppUser?.accesslevel);
            }
        });
        if (user !== null) {
            setTeacherInfo([user.uid, user.providerData[0].displayName!, user.providerData[0].email!]);
        }
    }, [props.user]);

    useEffect(() => {
        if (props.textMatTopic !== null) {
            setTopic(props.textMatTopic);
        }
        console.log("textmat topic: ", topic);
        console.log("textmat subject: ", subject);
        console.log("textmat grade: ", grade);
        if (props.subjectfromurl !== null) {
            setSubject(props.subjectfromurl);
        }
        console.log("subject: ", subject);
        if (props.gradefromurl !== null) {
            setGrade(props.gradefromurl);
        }
    }, [props.textMatTopic, topic, props.subjectfromurl, subject, props.gradefromurl, grade]);
    const [justAddedTextMaterial, setJustAddedTextMaterial] = useState(false);
    const handleAddTextTypeMaterial = async () => {
        if (editorRef.current) {
            console.log("editorRef.current: ", editorRef.current.getContent());
            content = (editorRef.current.getContent());
            console.log("first if worked, content : ", content);
            if (!props.user || !content) {
                console.log("User not found (or no content)");
                return;
            }

            if (content === "<h1>Ders sayfasını oluşturmaya başlamak için burayı düzenleyin.</h1></br><h2>Modifiez ici pour créer la page de cours.</h2>") {
                console.log("Content is the initial content");
                return;
            }

            if (!subject || !grade || !materialType || !topic) {
                console.log("Please fill in all fields", content, subject, grade, materialType, topic);
                return;
            }

            // Check if there are existing text materials with the same topic
            console.log(topic);
            var existingTextMaterial = textTypeMaterial.find((material) => material.topic === topic);

            if (existingTextMaterial || justAddedTextMaterial) {
                // If there's an existing text material with the same topic, update it
                await editMaterial(
                    subject,
                    grade,
                    'text', // Provide the material type
                    content, // Provide the updated content
                    topic, // Provide the updated topic
                    "",
                    true, // Provide the visibility status
                    existingTextMaterial
                ).then((result) => {
                    console.log(result);
                    console.log('Material updated successfully');
                    setShowTextMatUpdateConfirmationElement(true);
                    setTimeout(() => {
                        setShowTextMatUpdateConfirmationElement(false);
                    }, 3000);
                }).catch((error) => {
                    console.log(error);
                });

            } else {
                const tsnow = Timestamp.now();
                // If there's no existing text material with the same topic, create a new one
                await addMaterial(materialName, subject, grade, content, teacherinfo, 'text', topic, tsnow);
                console.log('Material added successfully');
                existingTextMaterial = Object.assign({}, existingTextMaterial, { materialName, subject, grade, content, teacherinfo, materialType, topic });
                setJustAddedTextMaterial(true);
                setShowTextMatConfirmationElement(true);
                setTimeout(() => {
                    setShowTextMatConfirmationElement(false);
                }, 3000);
            }
        } else {
            console.log('Editor reference is null');
        }
    };

    const handleGetTextTypeMaterial = async () => {
        if (props.user) {
            if (subject && grade && topic) {
                try {
                    const colRef = collection(db, 'materials', subject, grade);
                    const docSnap = await getDocs(colRef);
                    if (docSnap.docs.length > 0) {
                        const data = docSnap.docs.map(doc => doc.data() as Material);
                        // set texttypematerial to the material whose materialType is text and topic is the chosen topic
                        const textTypeMaterial = data.filter((material) => {
                            console.log((material.materialtype === "text" && material.topic === topic), "material")
                            return material.materialtype === "text" && material.topic === topic;
                        });

                        setTextTypeMaterial(textTypeMaterial);
                        console.log(textTypeMaterial[0].content)
                        if (content !== textTypeMaterial[0].content) {
                            setContent(textTypeMaterial[0].content)
                        } else {
                            console.log("textmat: content is already the same as the material content");
                            setContent("<h1>Ders sayfasını oluşturmaya başlamak için burayı düzenleyin.</h1></br><h2>Editer ici pour créer la page de cours</h2>");
                        }
                    } else {
                        console.log('Document not found');
                        setContent("<h1>Ders sayfasını oluşturmaya başlamak için burayı düzenleyin.</h1></br><h2>Editer ici pour créer la page de cours</h2>");
                    }
                } catch (error) {
                    console.error('Error fetching text type Subjects:', error);
                    setContent("<h1>Ders sayfasını oluşturmaya başlamak için burayı düzenleyin.</h1></br><h2>Editer ici pour créer la page de cours</h2>");
                }
            } else {
                console.log("textmat:", subject, grade, topic, "subject,grade, topic one or multiple of them is missing");
            }
        }
    };

    useEffect(() => {
        handleGetTextTypeMaterial();
    }, [topic]);

    useEffect(() => {
        if (content === "") {
            setContent("<h1>Ders sayfasını oluşturmaya başlamak için burayı düzenleyin.</h1></br><h2>Editer ici pour créer la page de cours</h2>");
        }

    }, [content]);

    const [isTextEditorContentVisible, setIsTextEditorContentVisible] = useState(true);
    const handleToggleTextEditorContent = () => {
        setIsTextEditorContentVisible(!isTextEditorContentVisible);
    };

    if (appUser && (appUser.isAdmin || appUser.accesslevel === "teacher")) {
        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                {(appUser && (appUser.isAdmin || appUser?.accesslevel === "teacher") && (props.subjectfromurl !== null && props.gradefromurl !== null && props.textMatTopic !== null)) &&
                    <div className="admin-widget">
                        <div className="admin-widget-title" onClick={handleToggleTextEditorContent}>
                            <b>🖊️ {t("footer.texteditor")}</b>
                        </div>
                        <div className={`admin-widget-content ${isTextEditorContentVisible ? 'visible' : ''}`}>
                            <hr />
                            <div className="admin-subwidget">
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ margin: "1%" }}>
                                        <Editor
                                            apiKey='9c4w66iz6vruireao4831u1l8ih70vll5w3kelbofma43dwa'
                                            onInit={(evt, editor) => (handleEditorInit(), (editorRef.current = editor))}
                                            initialValue={content}
                                            init={{
                                                height: "84vw",
                                                width: "60vw",
                                                menubar: true,
                                                plugins: [
                                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'media', 'autolink', 'autosave',
                                                    'image imagetools', 'preview', 'searchreplace', 'table', 'visualblocks', 'accordion', 'paste', 'directionality',


                                                ],
                                                toolbar: 'undo redo | blocks | ' +
                                                    'bold italic underline strikethrough forecolor | link media image imagetools accordion | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                content_css: "../styles/editorCustomStyles.css",
                                                link_default_target: '_blank',
                                                autosave_restore_when_empty: true,
                                                autosave_interval: '30s',
                                                imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
                                                image_title: true,
                                                /* enable automatic uploads of images represented by blob or data URIs*/
                                                automatic_uploads: true,

                                                file_picker_types: 'image',
                                                /* custom image picker here */
                                                file_picker_callback: function (cb, value, meta) {
                                                    var input = document.createElement('input');
                                                    input.setAttribute('type', 'file');
                                                    input.setAttribute('accept', 'image/*');

                                                    /*
                                                    Note: In modern browsers input[type="file"] is functional without
                                                    even adding it to the DOM, but that might not be the case in some older
                                                    or quirky browsers like IE, so you might want to add it to the DOM
                                                    just in case, and visually hide it. And do not forget do remove it
                                                    once you do not need it anymore.
                                                    */

                                                    input.onchange = function (e) {
                                                        var file: File = (e.target as HTMLInputElement).files![0];

                                                        var reader = new FileReader();
                                                        reader.onload = function () {
                                                            /*
                                                            Note: Now we need to register the blob in TinyMCEs image blob
                                                            registry. In the next release this part hopefully won't be
                                                            necessary, as we are looking to handle it internally.
                                                            */
                                                            var id = 'blobid' + (new Date()).getTime();
                                                            var blobCache = (window as any).tinymce.activeEditor.editorUpload.blobCache;
                                                            var base64 = (reader.result! as string).split(',')[1];
                                                            var blobInfo = blobCache.create(id, file, base64);
                                                            blobCache.add(blobInfo);

                                                            /* call the callback and populate the Title field with the file name */
                                                            cb(blobInfo.blobUri(), { title: file.name });
                                                        };
                                                        reader.readAsDataURL(file);
                                                    };

                                                    input.click();
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                                {/* ADD TEXT MATERIAL */}
                                <button className={`
                                            ${showTextMatUpdateConfirmationElement || showTextMatConfirmationElement ? 'admin-button-confirm' : ''}
                                            admin-button
                                            colored-button
                                            hover-up
                                                `}
                                    onClick={handleAddTextTypeMaterial}>
                                    <SendIcon />
                                    {showTextMatUpdateConfirmationElement || showTextMatConfirmationElement ? t("admin.mat-published") :
                                        t("admin.mat-publish")}
                                </button>                                
                            </div>
                        </div>
                    </div>
                }

            </div>
        )
    } else if (appUser?.accesslevel === "student" && appUser?.isAdmin === false) {
        //wait for appUser to be fetched properly, return the page content after that according to accesslevel. if not admin or teacher, return access denied
        return (
            <>
                <div className="page-wrapper admin-wrapper">
                    <div className="admin-widget">
                        <div className="admin-widget-title">
                            <b>🚫 Erişim Engellendi</b> / Accès refusé <br />
                            <b>🚫 Yetkisiz kullanıcı</b> / Utilisateur non autorisé <br />
                            🚫 <i>Access denied</i> <br /> <hr />
                            <b>Yönetici veya Öğretmen Yetkisi Gerekiyor</b> / Accès réservé aux administrateurs et enseignants
                        </div>
                        <div className="admin-widget-content">
                            <hr />
                            <div className="admin-subwidget">
                                <div className="admin-subwidget-title"><b>Yönetici veya Öğretmen Yetkisi Gerekiyor</b> / Accès réservé aux administrateurs et enseignants</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return null

    }
};

export default TextEditor;