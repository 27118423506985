import React, { useState, useEffect } from 'react';
import '../styles/mainpage-styles.css';
import '../styles/menupage.css';
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut } from 'firebase/auth';
import AuthPage, { auth } from './authpage';
import { User } from 'firebase/auth';
import { AuthProvider } from '../contexts/AuthContext';
import { Alert, AppUser } from '../serverlib/util-types';
import { getAppUser } from "../serverlib/db";
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../components/languageSelector';
function MainPage({ user }: { user: User | null }) {
    const [t, i18n] = useTranslation("common");
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'tr';
    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
        console.log("chosen language : ", i18n.language);
    }, [selectedLanguage, i18n]);

    var [appUser, setAppUser] = useState<AppUser | null>(null);

    useEffect(() => {
        getAppUser(user?.uid!).then(AppUser => {
            if (AppUser !== null) {
                setAppUser(AppUser as AppUser);
                console.log("user accesslevel(s) : ", AppUser?.accesslevel);
            }
        });
    }, [user]);
    const [loadingAppuser, setLoading] = useState(true);
    useEffect(() => {
        if (appUser !== null) {
            setLoading(false);
        }
    }, [appUser]);

    if (user && !loadingAppuser) {
        console.log(localStorage.getItem('selectedGrade'));
        console.log(user.uid);
        console.log(user.providerData[0].email);
        return (
            <div className="page-wrapper">
                <title>Metaclass</title>
                <meta name='description' content='Education but cooler, made my SJ students.' />

                <div className="header-frost"></div>
                <div className="header">
                    <div className="header-left">
                        <a href="/"><img style={{ height: "100%" }} src={require('../img/sj-white.png')} alt="School Logo" /></a>
                        <div className="vertical-line"></div>
                        {user && <p className="greeting">
                            <span className="greeting-sentence">{t("mainpage.greeting")},</span>
                            <span>{user?.providerData[0].displayName}</span>
                        </p>}
                        {/* <GroupList user={user}/> */}
                    </div>
                    <button className="generic-button hover-up colored-button" onClick={() => { signOut(auth) }}><LogoutIcon /></button>
                </div>

                <div className="menu-widget">
                    <div className="section-title">🍴 <b>{t("menupage.weekly-menu")}</b></div>
                    <div className="menu-day-wrapper">
                        <div className="menu-day">
                            <p>11.03.2024</p>
                            <h3>PAZARTESİ</h3>
                            <div className="menu-contents">
                                <div className="menu-item">
                                    <p>DOMATES ÇORBA</p>
                                    <p className="menu-cals">139 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>SUCUKLU KURU FASULYE</p>
                                    <p className="menu-cals">370 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>ISPANAK YAŞMAKLI</p>
                                    <p className="menu-cals">230 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>PİRİNÇ PİLAVI</p>
                                    <p className="menu-cals">326 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>SARI BURMA</p>
                                    <p className="menu-cals">310 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>SALAT BAR/MEYVE</p>
                                    <p className="menu-cals">150 kcal</p>
                                </div>
                            </div>
                        </div>


                        <div className="menu-day">
                            <p>12.03.2024</p>
                            <h3>SALI</h3>
                            <div className="menu-contents">
                                <div className="menu-item">
                                    <p>EZOGELİN ÇORBA</p>
                                    <p className="menu-cals">180 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>İZMİR KÖFTE</p>
                                    <p className="menu-cals">343 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>DOM. KARNABAHAR</p>
                                    <p className="menu-cals">110 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>ARPA ŞEHRİYE PİLAVI</p>
                                    <p className="menu-cals">345 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>KAZANDİBİ</p>
                                    <p className="menu-cals">230 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>SALAT BAR/MEYVE</p>
                                    <p className="menu-cals">150 kcal</p>
                                </div>
                            </div>
                        </div>

                        <div className="menu-day">
                            <p>13.03.2024</p>
                            <h3>ÇARŞAMBA</h3>
                            <div className="menu-contents">
                                <div className="menu-item">
                                    <p>HARIRA ÇORBA</p>
                                    <p className="menu-cals">245 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>PİLİÇ ŞNİTZEL</p>
                                    <p className="menu-cals">405 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>NOHUT</p>
                                    <p className="menu-cals">346 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>PİRİNÇ PİLAVI</p>
                                    <p className="menu-cals">326 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>SEKERPARE</p>
                                    <p className="menu-cals">369 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>SALAT BAR/MEYVE</p>
                                    <p className="menu-cals">150 kcal</p>
                                </div>
                            </div>
                        </div>

                        <div className="menu-day">
                            <p>14.03.2024</p>
                            <h3>PERŞEMBE</h3>
                            <div className="menu-contents">
                                <div className="menu-item">
                                    <p>TEL ŞEHRİYE ÇORBA</p>
                                    <p className="menu-cals">144 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>SALÇALI KÖFTE</p>
                                    <p className="menu-cals">349 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>DOMATESLİ BAMYA</p>
                                    <p className="menu-cals">130 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>MAKARNA</p>
                                    <p className="menu-cals">250 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>KEŞKÜL</p>
                                    <p className="menu-cals">281 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>SALAT BAR/MEYVE</p>
                                    <p className="menu-cals">150 kcal</p>
                                </div>
                            </div>
                        </div>
                        <div className="menu-day">
                            <p>15.03.2024</p>
                            <h3>CUMA</h3>
                            <div className="menu-contents">
                                <div className="menu-item">
                                    <p>YAYLA ÇORBA</p>
                                    <p className="menu-cals">158 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>SEBZELİ KEBAP</p>
                                    <p className="menu-cals">220 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>YEŞİL MERCİMEK</p>
                                    <p className="menu-cals">267 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>BULGUR PİLAVI</p>
                                    <p className="menu-cals">291 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>BİSKÜVİLİ PASTA</p>
                                    <p className="menu-cals">361 kcal</p>
                                </div>
                                <div className="menu-item">
                                    <p>SALAT BAR/MEYVE</p>
                                    <p className="menu-cals">150 kcal</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="https://sj.k12.tr/wp-content/uploads/pdf/yemek-menusu.pdf">PDF haline ulaşmak için tıklayın.</a>
                </div>


                <div style={{ height: "354px" }}></div>

                <div className="footer">
                    <p>©2024 <a href="/team">Metaclass</a></p>
                    <span className="footer-links">
                        {/* {(appUser?.isAdmin) && (
              <>
                <a href="/texteditor" rel="noreferrer">{t("footer.texteditor")}</a>
                <span className="link-dot">•</span>
              </>
            )}

            {(appUser?.accesslevel === "teacher") && (
              <>
                <a href="/myclasses" rel="noreferrer">{t("footer.myclasses")}</a>
                <span className="link-dot">•</span>
              </>
            )} */}

                        {(appUser?.isAdmin) && ( // || appUser?.accesslevel === "teacher" removed from conditions: teachers should use their subject's page to edit.
                            <>
                                <a href="/admin" rel="noreferrer">{t("footer.admin")}</a>
                                <span className="link-dot">•</span>
                            </>
                        )}
                        <a href="https://forms.gle/1dXApjbXShd4mSZD8" target="_blank" rel="noreferrer">{t("footer.feedback")}</a>
                        <span className="link-dot">•</span> <LanguageSelector />

                    </span>
                </div>
            </div>

        );
    } else if (user && loadingAppuser) {
        return (
            <div
                style={{ color: "white", alignContent: "center", alignItems: "center", textAlign: "center", justifyContent: "center", display: "flex", height: "100vh", width: "100vw", fontSize: "24px" }}>
                <p>
                    <b>Yükleniyor...</b>
                    <br />
                    Chargement...
                </p>
            </div>
        )
    }
    else return <AuthProvider><AuthPage user={user} /> </AuthProvider>
}

export default MainPage;
