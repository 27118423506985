import React, { useEffect, useState } from "react";
import "../styles/subject-page.css";
import "../styles/mainpage-styles.css";
import "../styles/editorCustomStyles.css"
import { User } from "firebase/auth";
import { getMaterials, getAlertsByImpAndLessonandgrade, delMaterial, getTopics, editMaterial } from "../serverlib/db";
import { AuthProvider } from "../contexts/AuthContext";
import AuthPage from "./authpage";
import { useLocation } from 'react-router-dom';
import { Subject, Alert, Material, Topic, AppUser } from "../serverlib/util-types";
import { getAppUser } from "../serverlib/db";
import Admin from "./admin";
import TextEditor from "./texteditor";
import TopicsPage from "../components/topics";
import MaterialAdd from "../components/materialAdd";
import LanguageSelector from "../components/languageSelector";
//icons
import EditNoteIcon from '@mui/icons-material/EditNote';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import HomeIcon from '@mui/icons-material/Home';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

//TRANSLATE PAGE ACCORDING TO THE LANGUAGE OF THE LESSON.
import { useTranslation } from "react-i18next";
import { SearchQueryMaterials } from "../components/SearchQueryMaterials";
import GenericPdfDownloader from "../components/htmlToPdfDownload";
// import { ref } from "firebase/storage";
// import { storage } from "../serverlib/storage";
interface SubjectPageProps {
  user: User | null;
  appUser: AppUser | null;
}
function SubjectPage(props: SubjectPageProps) {
  const [t, i18n] = useTranslation('common');
  const user = props.user;
  var [appUser, setAppUser] = useState<AppUser | null>(null);

  const currentPath = useLocation().pathname;
  const [materials, setMaterials] = useState<Material[]>([]);
  const [alertList3, setAlertList3] = useState<Alert[]>([]);
  const [alertList4, setAlertList4] = useState<Alert[]>([]);
  const [textMatTopicSubtopic1, setTextMatTopicSubtopic1] = useState("");
  const [textMatTopicUnit, setTextMatTopicUnit] = useState("");
  const [topicList, setTopicList] = useState<Topic[]>([]);
  // Split the path using '/'
  const pathParts = currentPath.split('/');
  const grade = pathParts[2];
  const subjectfromurl = pathParts[3];
  const subject = decodeURIComponent(subjectfromurl) as Subject;
  const subjectbasicform = decodeURIComponent(subjectfromurl);
  // change the subject name derived from the url to the one that will be displayed
  var subjectToDisplay = subjectbasicform;

  //#region subjectToDisplay
  if (subjectbasicform === "türkdiliveedebiyatı") {
    subjectToDisplay = "Türk Dili ve Edebiyatı";
  } else if (subjectbasicform === "mathématiques") {
    subjectToDisplay = "Mathématiques";
  } else if (subjectbasicform === "physique") {
    subjectToDisplay = "Physique";
  } else if (subjectbasicform === "chimie") {
    subjectToDisplay = "Chimie";
  } else if (subjectbasicform === "biologie") {
    subjectToDisplay = "Biologie";
  } else if (subjectbasicform === "bilgisayarbilimi") {
    subjectToDisplay = "Bilgisayar Bilimi";
  } else if (subjectbasicform === "felsefe") {
    subjectToDisplay = "Felsefe";
  } else if (subjectbasicform === "psikoloji") {
    subjectToDisplay = "Psikoloji";
  } else if (subjectbasicform === "sosyoloji") {
    subjectToDisplay = "Sosyoloji";
  } else if (subjectbasicform === "tarih") {
    subjectToDisplay = "Tarih";
  } else if (subjectbasicform === "coğrafya") {
    subjectToDisplay = "Coğrafya";
  } else if (subjectbasicform === "müzik") {
    subjectToDisplay = "Müzik";
  } else if (subjectbasicform === "görselsanatlar") {
    subjectToDisplay = "Görsel Sanatlar";
  } else if (subjectbasicform === "bedeneğitimi") {
    subjectToDisplay = "Beden Eğitimi";
  } else if (subjectbasicform === "français") {
    subjectToDisplay = "Français";
  } else if (subjectbasicform === "english") {
    subjectToDisplay = "English";
  } else if (subjectbasicform === "sağlıkbilgisivetrafikkültürü") {
    subjectToDisplay = "Sağlık Bilgisi ve Trafik Kültürü";
  } else if (subjectbasicform === "dinkültürüveahlakbilgisi") {
    subjectToDisplay = "Din Kültürü ve Ahlak Bilgisi";
  } else if (subjectbasicform === "sciences") {
    subjectToDisplay = "Sciences";
  } else if (subjectbasicform === "seçmelitürkdiliveedebiyatı") {
    subjectToDisplay = "Seçmeli Türk Dili ve Edebiyatı";
  } else if (subjectbasicform === "tci̇nkılaptarihiveatatürkçülük") {
    subjectToDisplay = "T.C. İnkılap Tarihi ve Atatürkçülük";
  } else if (subjectbasicform === "psikoloji") {
    subjectToDisplay = "Psikoloji";
  } else if (subjectbasicform === "mantık") {
    subjectToDisplay = "Mantık";
  } else if (subjectbasicform === "çağdaştürkvedünyatarihi") {
    subjectToDisplay = "Çağdaş Türk ve Dünya Tarihi";
  } else if (subjectbasicform === "türkkültürvemedeniyettarihi") {
    subjectToDisplay = "Türk Kültür ve Medeniyet Tarihi";
  } else if (subjectbasicform === "emi") {
    subjectToDisplay = "EMI";
  }

  const subjectImage = require(`../img/${subjectbasicform}.jpg`);

  //#endregion subjectToDisplay
  // Get the grade from the path
  var gradeint = String(grade.match(/\d+/g));
  if (gradeint === "null") {
    gradeint = "Prep";
  }
  const numericGrade = gradeint ? parseInt(gradeint[0]) : undefined;

  var gradetoshow;
  function getGrade() {
    if (numericGrade !== null && numericGrade !== undefined && gradeint !== undefined && gradeint !== null) {
      gradetoshow = gradeint + t("subjectpage.thgrader");
      if (gradeint === "Prep") {
        gradetoshow = t("subjectpage.prep");
      }
    } else {
      gradetoshow = t("subjectpage.prep") as string;
    }
    console.log("getGrade run: ", gradetoshow);
  }
  getGrade();
  // Fetch materials from Firestore and map them as material-item's based on gradeint and subject(includes)
  const handleGetTopics = async () => {
    try {
      console.log("subject topic sp: ", subject);
      console.log("gradetopic sp: ", "subjects" + gradeint);
      getTopics(subject, "subjects" + gradeint).then((topics: Topic[]) => {

        setTopicList(topics);
        console.log('Topics:', topicList);
        return topics as Topic[];
      });
    } catch (error) {
      console.error('Error fetching topics:', error);
    }
  }

  useEffect(() => {
    handleGetTopics().then((topics) => { console.log("topics: ", topics) });
  }, []);
  useEffect(() => {
    getAppUser(user?.uid!).then(AppUser => {
      if (AppUser !== null) {
        setAppUser(AppUser as AppUser);
        console.log("user accesslevel(s) : ", AppUser?.accesslevel);
      }
    });
  }, [user]);

  //fetch text materials to later map them by topic and grade and subject
  useEffect(() => {
    setTextMatTopicUnit(" ");
    setTextMatTopicSubtopic1(" ");
    setTextMatTopicUnit("");
    setTextMatTopicSubtopic1("");
    const fetchMaterials = async (subject: Subject, gradeint: string) => {
      await getMaterials(subject, gradeint).then((materials: Material[] | undefined) => {
        // materials && setMaterials(materials);
        console.log('Materials:', materials);
        setMaterials(materials as Material[]);
        return materials as Material[];
      });
    };

    // fetchMaterials(subject, gradeint);
    if (materials && materials.length === 0) {
      fetchMaterials(subject, gradeint);
    }

  }, [gradeint, subject]);

  //handle remove material
  const handleRemoveMaterial = async (material: Material) => {
    try {
      await delMaterial(material, gradeint, subject, textMatTopic).then(() => {
        console.log('Material successfully deleted: ', material.name, "\n", "Grade: ", gradeint, "\n", "Subject: ", subject);
        // Remove the deleted material from the materials array, dont remove if the deleted material's creation time is not the same as another material's. sometimes an additional material is accidentally added with the same content but at a random time. only remove one of the materials.
        const newMaterials = materials.filter((mat) => mat.content !== material.content || mat.time !== material.time);
        setMaterials(newMaterials);
      });
    } catch (error) {
      console.error('Error removing material:', error);
      console.error('Material:', material);
      console.error('Grade:', grade);
      console.error('Subject:', subject);
    }
  }
  //get alerts to map them
  useEffect(() => {
    const fetchAlerts = async () => {
      const importance3Alerts = await getAlertsByImpAndLessonandgrade(3);
      console.log("importance3Alerts: ", importance3Alerts);
      const filteredAlerts3 = importance3Alerts?.filter((alert3) =>
        alert3.lessonandgrade && alert3.lessonandgrade.includes(gradeint) && alert3.lessonandgrade.includes(subjectbasicform)
      );
      setAlertList3(filteredAlerts3 as Alert[]);

      const importance4Alerts = await getAlertsByImpAndLessonandgrade(4);
      const filteredAlerts4 = importance4Alerts?.filter((alert4) =>
        alert4.lessonandgrade && alert4.lessonandgrade.includes(gradeint) && alert4.lessonandgrade.includes(subjectbasicform)
      );
      setAlertList4(filteredAlerts4 as Alert[]);
    };

    fetchAlerts();

  }, []);

  //handle admin popup (add material- add alert) 
  const subjectpageadmin = () => {
    const adminPopupElement = document.querySelector('.admin-popup') as HTMLElement;
    adminPopupElement.style.display = 'block';
    document.body.style.overflow = 'hidden';
  };
  const closesubjectpageadmin = () => {
    const adminPopupElement = document.querySelector('.admin-popup') as HTMLElement;
    adminPopupElement.style.display = 'none';
    document.body.style.overflow = 'scroll';
  };

  const subjectpageaddmaterial = () => {
    const adminPopupElement = document.querySelector('.add-material-popup') as HTMLElement;
    adminPopupElement.style.display = 'block';
    document.body.style.overflow = 'hidden';
  }
  const closesubjectpageaddmaterial = () => {
    const adminPopupElement = document.querySelector('.add-material-popup') as HTMLElement;
    adminPopupElement.style.display = 'none';
    document.body.style.overflow = 'scroll';
  }

  const closesubjectpagetopics = () => {
    const adminPopupElement = document.querySelector('.topics-popup') as HTMLElement;
    adminPopupElement.style.display = 'none';
    document.body.style.overflow = 'scroll';
  };

  const subjectpagetexteditor = () => {
    const adminPopupElement = document.querySelector('.texteditor-popup') as HTMLElement;
    adminPopupElement.style.display = 'block';
    document.body.style.overflow = 'hidden';
  };
  const closesubjectpagetexteditor = () => {
    const adminPopupElement = document.querySelector('.texteditor-popup') as HTMLElement;
    adminPopupElement.style.display = 'none';
    document.body.style.overflow = 'scroll';
  };

  //update material visibility
  const updateMaterialVisibility = (material: Material, newVisibility: boolean) => {
    const newMaterials = materials.map((mat) => {
      if (mat.content === material.content) {
        mat.visible = newVisibility;
      }
      return mat;
    });
    setMaterials(newMaterials);
  }

  //get anchor topic= from url and decode it. that gives textMatTopic
  const [textMatTopicFromUrl, setTextMatTopicFromUrl] = useState("");
  useEffect(() => {
    if (localStorage.getItem("subjectpageSession")) {
      return;
    }
    const url = new URL(window.location.href);
    const textMatTopicFromUrl = url.searchParams.get("topic");
    setTextMatTopicFromUrl(textMatTopicFromUrl as string);
    console.log("textMatTopicFromUrl: ", textMatTopicFromUrl);
  }, []);

  //TOPIC PICKER LOGIC (change later for deeper levels of topics, chapters, subchapters etc.)
  const [textMatTopic, setTextMatTopic] = useState("/");
  useEffect(() => {
    setTextMatTopic(textMatTopicUnit + "/" + textMatTopicSubtopic1);
    console.log("textMatTopic: ", textMatTopic, "textMatTopicUnit: ", textMatTopicUnit, "textMatTopicSubtopic1: ", textMatTopicSubtopic1);
  }, [textMatTopic, textMatTopicSubtopic1, textMatTopicUnit]);
  useEffect(() => {
    if (textMatTopicFromUrl) {
      setTextMatTopic(textMatTopicFromUrl);
      setTextMatTopicUnit(textMatTopicFromUrl.split("/")[0]);
      setTextMatTopicSubtopic1(textMatTopicFromUrl.split("/")[1]);

    }
  }, [textMatTopicFromUrl]);

  console.log("subjectbasicform, subject : ", subjectbasicform, subject);

  //save the topc, lesson and grade to the local storage to be reset after 40 minutes. (the user may leave the page, so dont use session storage as it will be reset after the tab is closed, and  dont use settimeout as the user may leave the page and come back after 40 minutes)
  // one signle object.
  useEffect(() => {
    localStorage.setItem("subjectpageSession", JSON.stringify({ subject: subjectbasicform, grade: grade, topic: textMatTopic, resetByTime: new Date().getTime() + 2400000 })); //40 minutes
    console.log("subjectpageSession: ", JSON.parse(localStorage.getItem("subjectpageSession") as string));
  }, [subjectbasicform, grade, textMatTopic]);
  if (user) {
    return (
      <div className="page-wrapper">
        <div className="header-frost"></div>
        <div className="subject-header" style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0,0,0,0.5)), url(${subjectImage})`
        }}>
          <div className="header-left">
            <a href="/" style={{ display: "contents" }} onClick={() => { localStorage.setItem("subjectpageSession", '') }}><KeyboardBackspaceOutlinedIcon /></a>
            <div className="vertical-line"></div>
            <p className="greeting">
              <div className="greeting-sentence">{gradetoshow}</div>
              <div>{subjectToDisplay}</div>
            </p>
          </div>
          {/* EDIT PAGE BUTTON */}
          {(appUser?.isAdmin || appUser?.accesslevel === "teacher") && (
            <button className="icon-button hover-up" onClick={subjectpageadmin}><EditIcon /></button>
          )}
        </div>
        <div className="announcements-wrapper">
          <div className="section-title"><span>📢 <b>{t('subjectpage.announcements')}</b></span></div>
          {/* {t('subjectpage.announcements-title')} */}
          {alertList3.length > 0 && (
            <div id="alert-wrapper">
              {alertList3 && alertList3.map((alert, index) => (
                <div key={index}>
                  <div className="alert-item" key={alert.title}>
                    <h2>{alert.title}
                      <span>• {alert.author}</span>
                    </h2>
                    <p>{alert.message}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
          {alertList4.length > 0 && (
            <div id="announcement-wrapper">
              {alertList4 && alertList4.map((alert, index) => (
                <div key={index}>
                  <hr />
                  <div className="announcement-item" key={alert.title}>
                    <h2>{alert.title}
                      <span>• {alert.author}</span>
                    </h2>
                    <p>{alert.message}</p>
                  </div>
                </div>
              ))}
            </div>

          )}
          {alertList3.length === 0 && alertList4.length === 0 && (
            <div className="no-alerts-text"><b>{t('subjectpage.no-announcements-header')}</b>
              <br /> {t('subjectpage.no-announcements-text')}</div>
          )}
          <div style={{ height: "12px" }}></div>
        </div>

        <div className="materials-wrapper">
          <div className="section-title">📚 <b>{t('subjectpage.materials')}</b>
          </div>

          <div className="unit-radio-wrapper">
            <p style={{ marginBottom: "0", marginTop: "8px" }}>📖 <b>{t('subjectpage.filter-materials')}</b></p>

            <SearchQueryMaterials materials={materials} />

            <form className="unit-radio" name='unit' action="unit">
              <span><b>{t('subjectpage.topic-unit')}</b></span>
              <div className="unit-option-wrapper">
                <input type="radio"
                  id="unit-deselect"
                  name="unit"
                  value={""}
                  checked={textMatTopicUnit === ""}
                  onChange={(e) => {
                    setTextMatTopicSubtopic1(e.target.value as string);
                    setTextMatTopicUnit(e.target.value as string);
                  }} />
                <label htmlFor="unit-deselect"><HomeIcon /></label>
              </div>
              {topicList.length > 0 && topicList.map((topic, index) => (
                <div className="unit-option-wrapper" key={index}>
                  <input type="radio"
                    id={topic.tname}
                    name="unit"
                    value={topic.tname}
                    checked={textMatTopicUnit === topic.tname}
                    onChange={(e) => {
                      if (textMatTopicUnit !== topic.tname) {
                        setTextMatTopicSubtopic1("");
                      }
                      setTextMatTopicUnit(e.target.value as string);
                      console.log("topic:", e.target.value);
                    }} />
                  <label htmlFor={topic.tname}>{topic.tname}</label>
                </div>
              ))}

            </form>
            {textMatTopicUnit !== "" && (
              <>
                <form className="topic-radio" name='topic' action="topic">
                  <span><b>{t('subjectpage.topic-chapter')}</b></span>
                  <div className="topic-option-wrapper">
                    <input type="radio"
                      id="topic-deselect"
                      name="topic"
                      value={""}
                      checked={textMatTopicSubtopic1 === ""}
                      onChange={(e) => {
                        setTextMatTopicSubtopic1(e.target.value as string);
                      }} />
                    <label htmlFor="topic-deselect"><HomeIcon /></label>
                  </div>

                  {/* map chosen topic's subtopics to radio buttons */}
                  {(topicList.length > 0 && textMatTopicUnit !== "") &&
                    topicList.map((topic) => (
                      topic.tname === textMatTopicUnit && topic.subtopics.length > 0 &&
                      topic.subtopics.map((subtopic, index) => (
                        <div className="topic-option-wrapper" key={index}>

                          <input type="radio"
                            id={subtopic}
                            name="topic"
                            value={subtopic}
                            checked={textMatTopicSubtopic1 === subtopic}
                            onChange={(e) => {
                              setTextMatTopicSubtopic1(e.target.value as string);
                              console.log("topic:", textMatTopic);
                            }} />
                          <label htmlFor={subtopic}>{subtopic}</label>
                        </div>

                      ))
                    ))
                  }

                </form>
              </>
            )}
          </div>
          {/* <div style={{ alignSelf: "center", textAlign: "center", width: "100%", fontSize: "18px", fontWeight: "bold", marginTop: "12px" }}
            className="subject-textfield-topic-title">
            {t("subjectpage.topic-topic")} : {textMatTopic === "/" ? <>{t('subjectpage.mainpage')}</> : <>{textMatTopicUnit} / {textMatTopicSubtopic1.length !== 0 ? <>{textMatTopicSubtopic1}</> : <>{t('subjectpage.sub-general')}</>} </>}
          </div> */}
          <div className="materials-content">
            {/* add remove button for teachers, maybe even edit button */}
            {user && (appUser?.isAdmin || appUser?.accesslevel === "teacher") && (
              <div className="material-item hover-up add-material-item" onClick={subjectpageaddmaterial}>
                <NoteAddIcon />
                <p> {textMatTopic === "/" ? <>{t('subjectpage.mainpage')}</> : <>{textMatTopicUnit} / {textMatTopicSubtopic1.length !== 0 ? <>{textMatTopicSubtopic1}</> : <>{t('subjectpage.sub-general')}</>} </>} </p>
                <span style={{ textAlign: "center" }}><b>{t('subjectpage.add-material')}</b></span>
              </div>
            )}
            {materials && materials.map((material, index) => {
              if ((material.materialtype !== "text" && (material.visible || appUser?.isAdmin || appUser?.accesslevel === "teacher")) && material.topic === textMatTopic) {
                return (
                  <div className="material-item hover-up" key={index}>
                    <iframe className="material-item-preview" src={material.content} loading="eager" title="matpreview"></iframe>
                    <a className="material-item-info" href={material.content} target="_blank" rel="noreferrer">
                      <div className="material-item-title"> {material.name} </div>
                      <div className="material-item-details">
                        <div className="material-item-detail"> {material.materialtype} </div>
                        <div className="material-item-detail"> {material.teacherinfo[1]} </div>
                      </div>

                    </a>
                    {(user?.providerData[0].email === material.teacherinfo[2] || appUser?.isAdmin) && (
                      <div className="material-admin-actions">
                        <button className="icon-button" onClick={() => {
                          var newVisibility = !material.visible;
                          editMaterial(subject, gradeint, material.materialtype, material.content, material.topic, material.name, newVisibility, material).then((r) => {
                            if (r === "success") {
                              updateMaterialVisibility(material, newVisibility);
                              console.log("material visibility changed: ", material.visible);
                            }
                          })


                        }}>
                          {material.visible && material.visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </button>
                        <button className="icon-button" onClick={() => { handleRemoveMaterial(material); }}>
                          <DeleteIcon />
                        </button>
                      </div>
                    )}
                  </div>
                );
              }
              else return null;
            })}
            {/* if material of type != text lenth is 0 show no materials text NOT DONE */}


          </div>
          {/* EDIT POPUP BUTTON FOR TEXT FIELD. ADD TOPIC EDTOR SEPERATELY. */}
          <div className="textmat-actions">
            {((appUser?.isAdmin || appUser?.accesslevel === "teacher")) && ( //&& (materials&& materials.map((textmat) => {if (textmat.materialtype === "text") return true}))
              <button className="generic-button hover-up" onClick={subjectpagetexteditor}><EditNoteIcon />{t("admin.editcontent")}</button>
            )}
          </div>
          {materials && materials.map((textMaterial, index) => {

            if (textMaterial.materialtype === "text") {
              return (
                <div className="subject-textfield-area" key={index}>
                  {/* Check if textMatTopic matches the selected textMaterial's topic */}
                  {textMatTopic === textMaterial.topic && (
                    <>
                      {textMaterial && <GenericPdfDownloader rootElementId="download-as-pdf" downloadFileName={subject + gradeint + textMaterial.topic.replaceAll("/", "-")} />}
                      <div className="subject-textfield" style={{ backgroundColor: "white", color: "black" }}>
                        <div id="download-as-pdf" className="subject-textmat" dangerouslySetInnerHTML={{ __html: textMaterial.content }}>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            }
            return null;
          })}
          <br />

          {((materials && materials.length === 0) || (!materials)) && (
            console.log("materials.length: ", materials && materials.length),
            <div className="no-mats-text"><b>{t("subjectpage.no-materials-header")}</b>
              <br />{t("subjectpage.no-materials-text")}</div>
          )}

          {(appUser?.isAdmin || appUser?.accesslevel === "teacher") && (
            console.log("gradeint in subjectpage ", gradeint),
            <div className="add-material-popup">
              <div className="add-material-popup-wrapper">
                <div className="add-material-popup-header">
                  <h2>🖊️ <b>{t("admin.add-material")}</b></h2>
                  <button className="generic-button hover-up" onClick={closesubjectpageaddmaterial}> <CloseIcon /></button>
                </div>
                <div className="add-material-popup-body">
                  <div>
                    <MaterialAdd user={user} subjectfromurl={subject} gradefromurl={gradeint} actualGradefromurl={grade} defaultTopicFromTextMatTopic={textMatTopic} isOpenByDefault />
                  </div>
                </div>
              </div>
            </div>
          )}

          {(appUser?.isAdmin || appUser?.accesslevel === "teacher") && (
            <div className="admin-popup">
              <div className="admin-popup-wrapper">
                <div className="admin-popup-header">
                  <h2>🖊️ <b>{t("subjectpage.editpage")}</b></h2>
                  <button className="generic-button hover-up" onClick={closesubjectpageadmin}> <CloseIcon /></button>
                </div>
                <Admin user={user} subjectfromurl={subject} gradefromurl={gradeint} actualgradefromurlforsubject={grade} defaultTopicFromTextMatTopic={textMatTopic} />
                {/* <TextEditor user={user} subjectfromurl={subject} gradefromurl={gradeint} /> */}
              </div>
            </div>
          )}

          {(appUser?.isAdmin || appUser?.accesslevel === "teacher") && (
            <div className="texteditor-popup">
              <div className="texteditor-popup-wrapper">
                <div className="texteditor-popup-header">
                  <h2>🖊️ <b>{t("subjectpage.editpage")}</b> </h2>
                  <button className="generic-button hover-up" onClick={closesubjectpagetexteditor}> <CloseIcon /></button>
                </div>
                <div className="texteditor-popup-body">
                  <div>
                    <TextEditor user={user} subjectfromurl={subject} gradefromurl={gradeint} textMatTopic={textMatTopic} textMatTopicUnit={textMatTopicUnit} textMatTopicSubtopic1={textMatTopicSubtopic1} />
                  </div>
                </div>
              </div>
            </div>
          )}

          {(appUser?.isAdmin || appUser?.accesslevel === "teacher") && (
            <div className="topics-popup">
              <div className="topics-popup-wrapper">
                <div className="topics-popup-header">
                  <h2>🖊️ <b>Sayfayı Düzenle</b> / Modifier la page </h2>
                  <button className="generic-button hover-up" onClick={closesubjectpagetopics}> <CloseIcon /></button>
                </div>
                <div className="topics-popup-body">
                  <div>
                    <TopicsPage user={user} subjectfromurl={subject} gradefromurl={gradeint} />
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>
        <div className="footer">
          <p>©2024 <a href="/team">Metaclass</a></p>
          <span className="footer-links">
            {/* {(appUser?.accesslevel === "teacher") && (
              <>
                <a href="/myclasses" rel="noreferrer">{t("footer.myclasses")}</a>
                <span className="link-dot">•</span>
              </>
            )} */}

            {(appUser?.isAdmin) && ( // || appUser?.accesslevel === "teacher" removed from conditions: teachers should use their subject's page to edit.
              <>
                <a href="/admin" rel="noreferrer">{t("footer.admin")}</a>
                <span className="link-dot">•</span>
              </>
            )}
            <a href="https://forms.gle/1dXApjbXShd4mSZD8" target="_blank" rel="noreferrer">{t("footer.feedback")}</a>
            <span className="link-dot">•</span> <LanguageSelector />

          </span>
        </div>

      </div>
    );
  } else return <AuthProvider><AuthPage user={user} /></AuthProvider>
}

export default SubjectPage;
