import react, { useState, useEffect } from "react";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import {
    db, addMaterial, addAlert, getAlertsByImpAndLessonandgrade, delAlert, getAppUser, getSubjects, getUsers,
    updateUserAccessLevel, updateUserAdminStatus, updateUserGrades, updateUserSubjects, addMultipleMaterials, bulkDeleteMaterials
} from "../serverlib/db";
// import addTeacher from "../serverlib/db";
import { Subject, Alert, AppUser } from "../serverlib/util-types";
import { User } from "firebase/auth";
import HomeIcon from '@mui/icons-material/Home';

//styles
import "../styles/admin.css";
import "../styles/mainpage-styles.css";
import { auth } from "./authpage";
import { signOut } from "firebase/auth";
import LogoutIcon from '@mui/icons-material/Logout';
import TopicsPage from "../components/topics";
import MaterialAdd from "../components/materialAdd";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../components/languageSelector";

function Admin({ user, subjectfromurl, gradefromurl, actualgradefromurlforsubject, defaultTopicFromTextMatTopic }: { user: User | null, subjectfromurl: Subject | null, gradefromurl: string | null, actualgradefromurlforsubject: string | null, defaultTopicFromTextMatTopic: string | null }) {
    const { t } = useTranslation("common");
    var [appUser, setAppUser] = useState<AppUser | null>(null);
    //alert parameters
    const [alertMessage, setAlertMessage] = useState("");
    const [alertTitle, setAlertTitle] = useState("");
    const [alertImportance, setAlertImportance] = useState(Number);
    const alertAuthor = user?.providerData[0].displayName;
    //material parameters (add localstorage for grade and subject) !!
    var [material, setMaterial] = useState("");
    const [materialName, setMaterialName] = useState("");
    const [subject, setSubject] = useState<Subject | ''>(subjectfromurl as Subject); // Initialize subject state with empty string
    const [grade, setGrade] = useState("");
    const [alertList, setAlertList] = useState<any[]>([]);
    var [lessonandgrade, setLessonAndGrade] = useState("mainpage");
    var [userListAll, setUserListAll] = useState<AppUser[]>([]);
    console.log("fromurl : ", subjectfromurl, gradefromurl);
    // grade from url but only the integer part
    var gradefromurlshort = "";
    if (gradefromurl !== null) {
        gradefromurlshort = gradefromurl.replace(/\D/g, '');

        if (gradefromurlshort === "") {
            gradefromurlshort = "Prep";
        }
    }

    useEffect(() => {
        if (gradefromurl === null && appUser?.isAdmin) {
            getUsers().then((users) => {
                setUserListAll(users as AppUser[]);
                console.log("userlistall : ", userListAll);
            });
        }
    }, [gradefromurl, appUser?.isAdmin]);
    //available subjects for the selected grade

    // function to get alerts based on importance
    useEffect(() => {
        if ((appUser?.isAdmin) && (alertImportance === 1 || alertImportance === 2)) {
            lessonandgrade = "mainpage";
            setLessonAndGrade("mainpage"); //maybe remove ? dunno
        }
        const handleGetAlert = async () => {

            console.log("lessonandgrade : ", lessonandgrade);
            console.log(alertImportance);
            await getAlertsByImpAndLessonandgrade(alertImportance, lessonandgrade).then((alerts) => {
                // console.log("lessonandgrade (in handlegetalert): ", lessonandgrade);
                if ((alerts as Alert[]).length > 0) {
                    setAlertList(alerts as Alert[]);
                    return alerts;
                } else {
                    setAlertList([]);
                    console.log('Error getting alerts (length 0)');
                }
            })
        };
        handleGetAlert();
    }, [alertImportance, lessonandgrade]);

    const handleGoToRoot = () => {
        // Change window location to the root path
        window.location.href = '/';
    };

    // useEffect(() => {
    //     if (gradefromurl !== null && subjectfromurl !== null) {
    //         setAlertImportance(4);
    //         console.log("(FROMURLS NOT NULL) alertimportance set to :", alertImportance);
    //     }
    // } , [gradefromurl, subjectfromurl]);

    // function to add alerts
    const handleAlertSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (alertImportance !== 0 && alertMessage !== '' && alertTitle !== '' && alertAuthor !== '') {

            if (grade !== '' && subject !== '') {
                setLessonAndGrade("subjects" + grade + "/" + subject);
                console.log("lessonandgrade set to : subjects" + grade + "/" + subject);
            } else {
                console.log("lessonandgrade set to mainpage, grade or subject is empty");
                setLessonAndGrade("mainpage");
            }

            if (alertImportance < 3) {
                setLessonAndGrade("mainpage");
                console.log("lessonandgrade set to mainpage, alert importance is less than 3");
            }

            console.log("lessonandgrade : ", lessonandgrade);
            addAlert(alertAuthor!, alertImportance, alertMessage, alertTitle, lessonandgrade)
                .then(async (result) => {
                    if (result) {
                        console.log('Alert added successfully', result);
                        //add alert to alertList
                        setAlertList(await getAlertsByImpAndLessonandgrade(alertImportance, lessonandgrade))
                        //successful => Reset form fields
                        setAlertTitle('');
                        setAlertMessage('');

                    } else {
                        // Handle error
                        console.log('Error adding alert 0');
                    }
                })
                .catch((error) => {
                    // Handle error
                    console.log('Caught error adding alert', error.message);
                });
        } else {
            console.log('Missing fields: You must fill in all required fields to add an alert');
        }
    };
    // function to delete alerts
    const handleDeleteAlert = (alertAdmin: Alert) => {
        delAlert(alertAdmin)
            .then(() => {
                console.log('Alert deleted successfully');
                //update the alertList state to remove the deleted alert
                setAlertList((prevAlerts) => prevAlerts.filter((alert) => alert !== alertAdmin));
            })
            .catch((error) => {
                console.log('Error deleting alert:', error.message);
            });
    };

    //CHANGE THIS TO FIX SOME SUBJECTS NOT MAPPING CORRECTLY
    // Function to fetch subjects based on the selected grade
    // const fetchSubjects = async (grade: string) => {

    //     console.log("gradeint TOPICS: ", grade);

    //     const responsegrade = await require('../data/subjectsgeneralized.json');
    //     const datagrade = await JSON.parse(JSON.stringify(responsegrade));
    //     setAvailableSubjects(datagrade[grade] || []);

    // };

    // useEffect(() => {
    //     if (grade !== '') {
    //         fetchSubjects(grade).then((subjects) => {
    //             console.log('Subjects fetched successfully: ', subjects);
    //         });
    //     } else {
    //         console.log('No grade selected');
    //     }
    // }, [grade, gradefromurl, gradefromurlshort, actualgradefromurlforsubject]);

    //FILE UPLOAD (PDF, IMAGE, SOUND, VIDEO)
    //change function based on file type. (/images if jpg, png etc; /videos if mp4 etc...)
    // console.log("IMPORTANT GRADE AND SUBJECT PRINT : ", grade, subject, matTopic)
    //extensions for material types

    // var selectedValueImpAdmin = "";
    // var selectedValueImpTeacher = "";

    // const [topics, setTopics] = useState<string[]>([]);
    // const [subtopics, setSubtopics] = useState<{ [topic: string]: string[] }>({});
    // const [newTopic, setNewTopic] = useState('');
    // const [newSubtopic, setNewSubtopic] = useState('');
    // const [subtopicInputs, setSubtopicInputs] = useState<{ [topic: string]: string }>({});

    /*TOPICCCCCCCSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS */
    // const handleAddTopic = () => {
    //     if (newTopic.trim() !== '') {
    //         setTopics([...topics, newTopic]);
    //         setSubtopics({ ...subtopics, [newTopic]: [] });
    //         setNewTopic('');
    //     }
    // };

    // const handleAddSubtopic = (topic: string) => {
    //     const newSubtopicValue = subtopicInputs[topic].trim();

    //     if (newSubtopicValue !== '') {
    //         setSubtopics({
    //             ...subtopics,
    //             [topic]: [...(subtopics[topic] || []), newSubtopicValue],
    //         });
    //         setSubtopicInputs({ ...subtopicInputs, [topic]: '' });
    //     }
    // };

    // const handleDeleteTopic = (topic: string) => {
    //     const updatedTopics = [...topics].filter((t) => t !== topic);
    //     const updatedSubtopics = { ...subtopics };
    //     delete updatedSubtopics[topic];

    //     setTopics(updatedTopics);
    //     setSubtopics(updatedSubtopics);
    // };

    // const handleDeleteSubtopic = (topic: string, index: number) => {
    //     const updatedSubtopics = { ...subtopics };
    //     updatedSubtopics[topic].splice(index, 1);

    //     setSubtopics(updatedSubtopics);
    // };

    // const [editingTopic, setEditingTopic] = useState<string | null>(null);
    // const [editingSubtopic, setEditingSubtopic] = useState<{ topic: string, index: number } | null>(null);

    // const [editedTopicName, setEditedTopicName] = useState<string | null>(null);
    // const [editedSubtopicName, setEditedSubtopicName] = useState<string | null>(null);

    // const [editingInfo, setEditingInfo] = useState<{ topic: string | null, newName: string | null }>({ topic: null, newName: null });
    // const [editingSubtopicInfo, setEditingSubtopicInfo] = useState<{ topic: string | null, index: number | null, newName: string | null }>({ topic: null, index: null, newName: null });

    // interface SubtopicState {
    //     topic: string;
    //     index: number;
    // }

    // const handleEditTopic = (topic: string) => {
    //     setEditingInfo({ topic, newName: topic });
    // };

    // const handleSaveTopic = (topic: string, newName: string) => {
    //     setEditingInfo({ topic: null, newName: null });
    //     if (newName !== topic) {
    //         const updatedTopics = topics.map((t) => (t === topic ? newName : t));
    //         const updatedSubtopics = { ...subtopics };
    //         updatedSubtopics[newName] = updatedSubtopics[topic];
    //         delete updatedSubtopics[topic];

    //         setTopics(updatedTopics);
    //         setSubtopics(updatedSubtopics);
    //     }
    // };

    // const handleEditSubtopic = (topic: string, subtopicIndex: number) => {
    //     setEditingSubtopicInfo({ topic, index: subtopicIndex, newName: subtopics[topic][subtopicIndex] });
    // };

    // const handleSaveSubtopic = (topic: string, index: number, newName: string) => {
    //     setEditingSubtopicInfo({ topic: null, index: null, newName: null });
    //     if (newName !== subtopics[topic][index]) {
    //         const updatedSubtopics = { ...subtopics };
    //         updatedSubtopics[topic][index] = newName;

    //         setSubtopics(updatedSubtopics);
    //     }
    // };

    // const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, topic: string, index: number | null, isTopic: boolean) => {
    //     if (event.key === 'Enter') {
    //         event.preventDefault();
    //         if (isTopic) {
    //             handleSaveTopic(topic, editingInfo.newName || '');
    //         } else {
    //             handleSaveSubtopic(topic, index || 0, editingSubtopicInfo.newName || '');
    //         }
    //     }
    // };

    /*TOPICCCCCCCSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS end*/

    useEffect(() => {
        getAppUser(user?.uid!).then(AppUser => {
            if (AppUser !== null) {
                setAppUser(AppUser as AppUser);
                console.log("user accesslevel(s) : ", AppUser?.accesslevel);
            }
            if (AppUser?.accesslevel === "teacher") {
                setAlertImportance(4);
            }
            if (AppUser?.isAdmin === true && subjectfromurl === null && gradefromurl === null) {
                setAlertImportance(2);
                setLessonAndGrade("mainpage");
            } else if (AppUser?.isAdmin === true && subjectfromurl !== null && gradefromurl !== null) {
                setAlertImportance(4);
                setLessonAndGrade("subjects" + gradefromurlshort + "/" + subjectfromurl);
            }
        });

    }, [user]);

    useEffect(() => {
        if (grade !== '' && subject !== '') {
            setLessonAndGrade("subjects" + grade + "/" + subject);
        } else {
            console.log("lessonandgrade set to mainpage, grade or subject is empty");
            setLessonAndGrade("mainpage");
        }
    }, [grade, subject, []]);

    useEffect(() => {
        if (subjectfromurl !== null && gradefromurl !== null) {
            setSubject(subjectfromurl);
            setGrade(gradefromurl);
            // setGradeForAlert(gradefromurl);
            // setSubjectForAlert(subjectfromurl);
        }
        // if (subjectfromurl !== null && gradefromurl !== null) {
        //     setAlertImportance(3);
        //     console.log("alertimportance set to :", alertImportance );
        // }
    }, [subjectfromurl, gradefromurl]);
    // var filteredAlerts = alertList.filter(alert => alert.lessonandgrade === `${subjectfromurl}/${gradefromurl}`).map(alert => <div>{alert.message}</div>);

    const [isAlertContentVisible, setIsAlertContentVisible] = useState(false);
    const handleToggleAlertContent = () => {
        setIsAlertContentVisible(!isAlertContentVisible);
    };

    const [isSubjectAlertContentVisible, setIsSubjectAlertContentVisible] = useState(false);
    const handleToggleSubjectAlertContent = () => {
        setIsSubjectAlertContentVisible(!isSubjectAlertContentVisible);
    };

    const [isModifyUsersContentVisible, setIsModifyUsersContentVisible] = useState(false);
    const handleToggleModifyUsersContent = () => {
        setIsModifyUsersContentVisible(!isModifyUsersContentVisible);
    };

    const [isModifyRequestingUsersContentVisible, setIsModifyRequestingUsersContentVisible] = useState(false);
    const handleToggleModifyRequestingUsersContent = () => {
        setIsModifyRequestingUsersContentVisible(!isModifyRequestingUsersContentVisible);
    };

    const [isModifyExistingUsersContentVisible, setIsModifyExistingUsersContentVisible] = useState(true);
    const handleToggleModifyExistingUsersContent = () => {
        setIsModifyExistingUsersContentVisible(!isModifyExistingUsersContentVisible);
    };

    if (appUser && (appUser.isAdmin || appUser.accesslevel === "teacher") && (subjectfromurl !== null && gradefromurl !== null)) {
        //ders içi, admin veya hoca.
        return (
            <>
                <div className="admin-wrapper">
                    {(appUser && (appUser.isAdmin || appUser?.accesslevel === "teacher")) &&
                        <TopicsPage user={user} subjectfromurl={subjectfromurl as Subject} gradefromurl={gradefromurl} />
                    }
                    {(appUser && (appUser.isAdmin || appUser?.accesslevel === "teacher") && (subjectfromurl !== null && gradefromurl !== null)) &&
                        <MaterialAdd user={user} subjectfromurl={subjectfromurl as Subject} gradefromurl={gradefromurl}
                            actualGradefromurl={actualgradefromurlforsubject} defaultTopicFromTextMatTopic={defaultTopicFromTextMatTopic} />
                    }

                    {(appUser && (appUser.isAdmin || appUser?.accesslevel === "teacher") && (subjectfromurl !== null && gradefromurl !== null)) &&
                        <div className="admin-widget">
                            <div className="admin-widget-title" onClick={handleToggleSubjectAlertContent}>
                                <b>🏳️ {t("admin.lesson-announcements")}</b>
                            </div>
                            <div className={`admin-widget-content ${isSubjectAlertContentVisible ? 'visible' : ''}`}>
                                <hr />
                                <div className="admin-subwidget">
                                    <div className="admin-subwidget-title"><b>{t("admin.new-announcement")}</b></div>
                                    <form onSubmit={handleAlertSubmit}>
                                        <div className="admin-input-wrapper">
                                            <label htmlFor="alerttitle"><b>{t("admin.title")}</b></label>
                                            <input className="admin-input" type="text" name="alerttitle" placeholder={t("admin.new-announcement-title")} required
                                                value={alertTitle}
                                                onChange={(e) => setAlertTitle(e.target.value)}
                                            />
                                        </div>
                                        <div className="admin-input-wrapper">
                                            <label htmlFor="alertmessage"><b>{t("admin.content")}</b></label>

                                            <textarea style={{ resize: "vertical" }} className="admin-input" name="alertmessage" placeholder={t("admin.content")} required
                                                value={alertMessage}
                                                onChange={(e) => setAlertMessage(e.target.value)}
                                            />
                                        </div>

                                        <div className="admin-input-wrapper">
                                            <label className={`admin-checkbox ${alertImportance === 3 ? 'admin-checkbox-checked' : ''}`}>
                                                <input
                                                    type="checkbox"
                                                    name="alertCheckbox"
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            setAlertImportance(3);
                                                        }
                                                        else {
                                                            setAlertImportance(4);
                                                        }
                                                    }}
                                                />
                                                <b>{t("admin.important-announcement")}</b>
                                            </label>
                                        </div>
                                        <br />
                                        <div className="admin-confirmation">
                                            <div className="admin-subwidget-title"><b>{t("admin.preview")}</b></div>
                                            {alertImportance === 3 && <div className="alert-item" key={alertTitle}>
                                                <h2>{alertTitle || t("admin.title")}
                                                    <span>• {alertAuthor}</span>
                                                </h2>
                                                <p>{alertMessage || t("admin.content")}</p>
                                            </div>}

                                            {alertImportance !== 3 && <div className="announcement-item" key={alertTitle}>
                                                <h2>{alertTitle || t("admin.title")}
                                                    <span>• {alertAuthor}</span>
                                                </h2>
                                                <p>{alertMessage || t("admin.content")}</p>
                                            </div>}
                                        
                                            <button className="admin-button colored-button hover-up" type="submit" ><SendIcon /><span>{t("admin.publish")}</span></button>
                                        </div>
                                    </form>
                                </div>
                                <div className="admin-subwidget">
                                    <div className="admin-subwidget-title"><b>{t("admin.all-announcements")}</b></div>
                                    {/* map alerts whose "lessonandgrade" field match the chosen subject and grade */}

                                    {alertList && alertList.map((alertAdmin) => (
                                        <div>
                                            {(alertImportance === 3) &&
                                                <div className="alert-item" key={alertAdmin} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                    <div>
                                                        <h2>{alertAdmin.title}
                                                            <span>• {alertAdmin.author}</span>
                                                        </h2>
                                                        <p>{alertAdmin.message}</p>
                                                    </div>
                                                    <button className="generic-button hover-up" onClick={() => handleDeleteAlert(alertAdmin)}><DeleteIcon /> </button>
                                                </div>
                                            }
                                            {(alertImportance === 4) &&
                                                <div className="announcement-item" key={alertAdmin} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                    <div>
                                                        <h2>{alertAdmin.title}
                                                            <span>• {alertAdmin.author}</span>
                                                        </h2>
                                                        <p>{alertAdmin.message}</p>
                                                    </div>
                                                    <button className="generic-button hover-up" onClick={() => handleDeleteAlert(alertAdmin)}><DeleteIcon /> </button>
                                                </div>
                                            }
                                            <hr />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                </div >
            </>
        )
    } else if (appUser && ((appUser?.isAdmin) && (subjectfromurl === null || gradefromurl === null))) {
        return (
            <>
                <div className="admin-wrapper">
                    <div className="header-frost"></div>
                    <div className="header">
                        <div className="header-left">
                            <a href="/"><img style={{ height: "100%" }} src={require('../img/sj-white.png')} alt="School Logo" /></a>
                            <div className="vertical-line"></div>
                            {user && <p className="greeting">
                                <span className="greeting-sentence">{t("mainpage.greeting")},</span>
                                <span>{user?.providerData[0].displayName}</span>
                            </p>}
                            {/* <GroupList user={user}/> */}
                        </div>
                        <button className="generic-button hover-up colored-button" onClick={() => { signOut(auth) }}><LogoutIcon /></button>
                    </div>

                    <div className="page-wrapper admin-widget">
                        <div className="admin-widget-title" onClick={handleToggleAlertContent}>
                            <b>📢 {t("subjectpage.announcements")} ({t("subjectpage.mainpage")})</b>
                        </div>
                        <div className={`admin-widget-content ${isAlertContentVisible ? 'visible' : ''}`}>
                            <hr />
                            <div className="admin-subwidget">
                                <div className="admin-subwidget-title"><b>{t("admin.new-announcement")}</b></div>
                                <form onSubmit={handleAlertSubmit}>
                                    <div className="admin-input-wrapper">
                                        <label htmlFor="alerttitle"><b>{t("admin.title")}</b></label>
                                        <input className="admin-input" type="text" name="alerttitle" placeholder={t("admin.new-announcement-title")} required
                                            value={alertTitle}
                                            onChange={(e) => setAlertTitle(e.target.value)}
                                        />
                                    </div>
                                    <div className="admin-input-wrapper">
                                        <label htmlFor="alertmessage"><b>{t("admin.content")}</b></label>

                                        <textarea style={{ resize: "vertical", alignSelf: "center"}} className="admin-input" name="alertmessage" placeholder={t("admin.content")} required
                                            value={alertMessage}
                                            onChange={(e) => setAlertMessage(e.target.value)}
                                        />
                                    </div>

                                    <div className="admin-input-wrapper">
                                        <label className={`admin-checkbox ${alertImportance === 1 ? 'admin-checkbox-checked' : ''}`}>
                                            <input
                                                type="checkbox"
                                                name="alertCheckbox"
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setAlertImportance(1);
                                                    }
                                                    else {
                                                        setAlertImportance(2);
                                                    }
                                                }}
                                            />
                                            <b>{t("admin.important-announcement")}</b>
                                        </label>
                                    </div>
                                    <br />
                                    <div className="admin-confirmation">
                                        <div className="admin-subwidget-title"><b>{t("admin.preview")}</b>:</div>
                                        {alertImportance !== 1 && <div className="announcement-item" key={alertTitle}>
                                            <h2>{alertTitle || t("admin.title")}
                                                <span>• {alertAuthor}</span>
                                            </h2>
                                            <p>{alertMessage || t("admin.content")}</p>
                                        </div>}

                                        {alertImportance === 1 && <div className="alert-item" key={alertTitle}>
                                            <h2>{alertTitle || t("admin.title")}
                                                <span>• {alertAuthor}</span>
                                            </h2>
                                            <p>{alertMessage || t("admin.content")}</p>
                                        </div>}

                                        <button className="admin-button colored-button hover-up" type="submit" ><SendIcon /><span>{t("admin.publish")}</span></button>
                                    </div>
                                </form>
                            </div>
                            <div className="admin-subwidget">
                                <div className="admin-subwidget-title"><b>{t("admin.all-announcements")}</b></div>
                                {alertList && alertList.map((alertAdmin) => (
                                    <div>
                                        {(alertImportance === 1) &&
                                            <div className="alert-item" key={alertAdmin} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                <div>
                                                    <h2>{alertAdmin.title}
                                                        <span>• {alertAdmin.author}</span>
                                                    </h2>
                                                    <p>{alertAdmin.message}</p>
                                                </div>
                                                <button className="generic-button hover-up" onClick={() => handleDeleteAlert(alertAdmin)}><DeleteIcon /> </button>
                                            </div>
                                        }
                                        {(alertImportance === 2) &&
                                            <div className="announcement-item" key={alertAdmin} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                                <div>
                                                    <h2>{alertAdmin.title}
                                                        <span>• {alertAdmin.author}</span>
                                                    </h2>
                                                    <p>{alertAdmin.message}</p>
                                                </div>
                                                <button className="generic-button hover-up" onClick={() => handleDeleteAlert(alertAdmin)}><DeleteIcon /> </button>
                                            </div>
                                        }

                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>

                    {/* KULLANICILARI YÖNET ÖĞRETMEN-ÖĞRENCİ GEÇİŞLERİ , ADMİN YAP VS VS. */}

                    <div className="admin-widget">
                        <div className="admin-widget-title" onClick={handleToggleModifyUsersContent}>
                            <b>🖋️ {t("admin.manage-users")} </b>
                        </div>
                        <div className={`admin-widget-content ${isModifyUsersContentVisible ? 'visible' : ''}`}>
                            <hr />
                            <div className="admin-subwidget">
                                <div className="admin-widget-title" onClick={handleToggleModifyRequestingUsersContent}>
                                    <b> {t("admin.requesters")} </b>
                                </div>
                                <div className={`admin-widget-content ${isModifyRequestingUsersContentVisible ? 'visible' : ''}`}>
                                    <hr />
                                </div>
                            </div>
                            <div className="admin-subwidget">
                                <div className="admin-widget-title" onClick={handleToggleModifyExistingUsersContent}>
                                    <b>{t("admin.users")}</b>
                                </div>
                                <div className={`admin-widget-content ${isModifyExistingUsersContentVisible ? 'visible' : ''}`}>
                                    <hr />
                                    <div className="users-grid">
                                        {userListAll && userListAll.map((user, index) => {
                                            return (
                                                <div className="user-info-card" key={index} >
                                                    <div className="user-name-info">{user.name} <br />
                                                        <span>{user.mail}</span> <br />
                                                        <span>UID: {user.uid} <br /><br />
                                                        </span>
                                                    </div>
                                                    <select className="admin-dropdown" id="user-acces-level-select" defaultValue={user.accesslevel}
                                                        onChange={(e) => {
                                                            const userNewAccessLevel = e.target.value;
                                                            console.log("userNewAccessLevel for user", user.name, " : ", userNewAccessLevel);
                                                            updateUserAccessLevel(user.uid, userNewAccessLevel)
                                                            user.accesslevel = userNewAccessLevel;
                                                        }}
                                                    >
                                                        <option value="student">{t("admin.student")}</option>
                                                        <option value="teacher">{t("admin.teacher")}</option>
                                                    </select>


                                                    <div className="admin-input-wrapper">
                                                        <label className={`admin-checkbox ${user.isAdmin ? 'admin-checkbox-checked' : ''}`}>
                                                            <input
                                                                type="checkbox"
                                                                checked={user.isAdmin}
                                                                onChange={(e) => {
                                                                    const isAdmin = e.target.checked;
                                                                    console.log("New admin status for user", user.name, ": ", isAdmin);
                                                                    updateUserAdminStatus(user.uid, isAdmin);
                                                                    user.isAdmin = isAdmin;
                                                                    const updatedUserListAll = userListAll.map(u => u.uid === user.uid ? { ...u, isAdmin } : u);
                                                                    setUserListAll(updatedUserListAll);
                                                                }}
                                                            />
                                                            Admin
                                                        </label>
                                                    </div>

                                                    {/* <label className="switch">
                                                    <input type="checkbox" checked={user.isAdmin} onChange={(e) => {
                                                        const isAdmin = e.target.checked;
                                                        console.log("New admin status for user", user.name, ": ", isAdmin);
                                                        updateUserAdminStatus(user.uid, isAdmin);
                                                        user.isAdmin = isAdmin;
                                                        const updatedUserListAll = userListAll.map(u => u.uid === user.uid ? { ...u, isAdmin } : u);
                                                        setUserListAll(updatedUserListAll);
                                                    }} />
                                                    <span className="slider round"></span>
                                                </label> */}

                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                            {/* <div className="admin-subwidget-title"><b>{t("admin.users")}</b></div> */}
                            {/* <div className="list-users-big">
                                {userListAll.map((user, index) => {
                                    return (
                                        <div className="user-info-card" key={index} >
                                            <p>{t("admin.name")}: {user.name}</p>
                                            <p>{t("admin.accesslevel")}: </p>
                                            <select id="user-acces-level-select" defaultValue={user.accesslevel}
                                                onChange={(e) => {
                                                    const userNewAccessLevel = e.target.value;
                                                    console.log("userNewAccessLevel for user", user.name, " : ", userNewAccessLevel);
                                                    updateUserAccessLevel(user.uid, userNewAccessLevel)
                                                    user.accesslevel = userNewAccessLevel;
                                                }}
                                            >
                                                <option value="student">{t("admin.student")}</option>
                                                <option value="teacher">{t("admin.teacher")}</option>
                                            </select>
                                            <p>Mail: {user.mail}</p>
                                            <p>Admin: {(user.isAdmin)?.toString()}</p>
                                            <label className="switch">
                                                <input type="checkbox" checked={user.isAdmin} onChange={(e) => {
                                                    const isAdmin = e.target.checked;
                                                    console.log("New admin status for user", user.name, ": ", isAdmin);
                                                    updateUserAdminStatus(user.uid, isAdmin);
                                                    user.isAdmin = isAdmin;
                                                    const updatedUserListAll = userListAll.map(u => u.uid === user.uid ? { ...u, isAdmin } : u);
                                                    setUserListAll(updatedUserListAll);
                                                }} />
                                                <span className="slider round"></span>
                                            </label>
                                            <p style={{ fontSize: "10px" }}>uid: {user.uid}</p>
                                        </div>
                                    );
                                })}
                            </div> */}

                        </div>
                    </div>

                    <div className="footer" style={{ position: "sticky", margin: "auto auto 0 auto" }}>
                        <p>©2024 <a href="/team">Metaclass</a></p>
                        <span className="footer-links">
                            
                            {/* {(appUser?.accesslevel === "teacher") && (
                                <>
                                    <a href="/myclasses" rel="noreferrer">{t("footer.myclasses")}</a>
                                    <span className="link-dot">•</span>
                                </>
                            )} */}

                            {(appUser?.isAdmin) && ( // || appUser?.accesslevel === "teacher" removed from conditions: teachers should use their subject's page to edit.
                                <>
                                    <a href="/admin" rel="noreferrer">{t("footer.admin")}</a>
                                    <span className="link-dot">•</span>
                                </>
                            )}
                            <a href="https://forms.gle/1dXApjbXShd4mSZD8" target="_blank" rel="noreferrer">{t("footer.feedback")}</a>
                            <span className="link-dot">•</span> <LanguageSelector />

                        </span>
                    </div>

                </div>
            </>
        )

    } else if (appUser?.accesslevel === "student" && appUser.isAdmin === false) {
        //wait for appUser to be fetched properly, return the page content after that according to accesslevel. if not admin or teacher, return access denied
        return (
            <>
                <div className="page-wrapper access-denied">
                    <h3>🚫 Erişim Engellendi / Accès refusé</h3>
                    <b>Bu sayfaya erişiminiz, yönetici yetkiniz olmadığı için engellendi.</b>
                    <i>Votre accès à cette page a été refusé comme vous n'avez pas les droits d'administrateur.</i>
                    <br />
                    <button className="generic-button hover-up colored-button" onClick={handleGoToRoot}>
                        <HomeIcon />Ana Sayfa / Menu Principal
                    </button>

                    <br />
                    <i>-- çaan buraya istek formu yapıcakmış --</i>
                </div>
            </>
        )
    } else if (appUser && (appUser?.accesslevel === "teacher" && (gradefromurl === null || subjectfromurl === null))) {
        return (
            <>
                <div className="page-wrapper access-denied">
                    <h3>🚫 Erişim Engellendi / Accès refusé</h3>
                    <b>Bu sayfaya erişiminiz, yönetici yetkiniz olmadığı için engellendi.</b>
                    <i>Votre accès à cette page a été refusé comme vous n'avez pas les droits d'administrateur.</i>
                    <br />
                    <button className="generic-button hover-up colored-button" onClick={handleGoToRoot}>
                        <HomeIcon />Ana Sayfa / Menu Principal
                    </button>

                    <br />
                    <i>-- çaan buraya istek formu yapıcakmış --</i>
                </div>
            </>
        )

    } else {
        return null
    }
};

export default Admin;