import React, { StrictMode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import reportWebVitals from './reportWebVitals';
import { auth } from './pages/authpage'; // Import your authentication setup
import { User, getRedirectResult, GoogleAuthProvider } from 'firebase/auth';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_fr from "./translations/fr/common.json";
import common_tr from "./translations/tr/common.json";
import common_en from "./translations/en/common.json";

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        fr: {
            common: common_fr
        },
        tr: {
            common: common_tr
        },
    },
});




const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

function Root() {
  const [user, setUser] = useState<User | null>(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    
    return () => unsubscribe();
  }, [user]);

  return (
      <StrictMode>
        <BrowserRouter>
          <I18nextProvider i18n={i18next}>
            <App user={user}/>
          </I18nextProvider>
        </BrowserRouter>
      </StrictMode>
    );
}


root.render(<Root />);