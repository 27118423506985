import { User } from "firebase/auth";
import { Subject, AppUser, Material, Topic } from "../serverlib/util-types";
import { useEffect, useState } from "react";
import { addMaterial, addMultipleMaterials } from "../serverlib/db";
import { Timestamp } from "firebase/firestore";
import { uploadFile, storage } from "../serverlib/storage";
import "../styles/admin.css";
import { getDownloadURL, ref } from "firebase/storage";
import { PickerCallback } from "react-google-drive-picker/dist/typeDefs";
import useDrivePicker from 'react-google-drive-picker'
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import SendIcon from '@mui/icons-material/Send';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useTranslation } from "react-i18next";

// export declare let google: any;
// export async function getGAPIToken(): Promise<string> {
//     if (localStorage.getItem("gapi_tokenrevoke")) {
//          if (Date.now() < parseInt(localStorage.getItem("gapi_tokenrevoke")!)) return localStorage.getItem("gapi_accesstoken")!;
//     }

//     return new Promise((resolve) => {
//          google.accounts.oauth2.initTokenClient({
//               client_id: "1085001691243-ug01tm2j5kkirg79m76ctbt1sl1q6q3j.apps.googleusercontent.com",
//               scope: "https://www.googleapis.com/auth/drive",
//               callback: (authResponse: { access_token: string; token_type: string; expires_in: number; scope: string; authuser: string; prompt: string; }) => {
//                    localStorage.setItem("gapi_accesstoken", authResponse.access_token);
//                    localStorage.setItem("gapi_tokenrevoke", (Date.now() + authResponse.expires_in * 1000).toString());
//                    resolve(authResponse.access_token);
//               },
//          }).requestAccessToken();
//     });
// }
export interface MaterialAddProps {
    user: User | null;
    subjectfromurl: string | null;
    gradefromurl: string | null;
    actualGradefromurl: string | null;
    defaultTopicFromTextMatTopic: string | null;
    isOpenByDefault?: boolean;
}
const MaterialAdd = (props: MaterialAddProps) => {
    //load props
    const user = props.user;
    const { t } = useTranslation("common");
    var subjectfromurl = props.subjectfromurl;
    var gradefromurl = props.gradefromurl;
    var actualGradefromurl = props.actualGradefromurl;
    var defaultTopicFromTextMatTopic = props.defaultTopicFromTextMatTopic;

    //material parameters (add localstorage for grade and subject) !!
    var [material, setMaterial] = useState("");
    const [materialName, setMaterialName] = useState("");
    const [subject, setSubject] = useState<Subject | ''>(props.subjectfromurl as Subject); // Initialize subject state with empty string
    const [grade, setGrade] = useState("");
    // const [subjectforalert, setSubjectForAlert] = useState<Subject | ''>(''); // Initialize subject state with empty string
    // const [gradeforalert, setGradeForAlert] = useState("");
    const [matTopic, setMatTopic] = useState("");
    const [materialtype, setMaterialType] = useState("");
    const [availableSubjects, setAvailableSubjects] = useState<string[]>([]);
    const [teacherInfo, setTeacherInfo] = useState<string[]>([]);
    const [gapiToken, setGapiToken] = useState<string | null>(null);

    //user loads
    useEffect(() => {
        if (user === null) return;
        else setTeacherInfo([user.uid, user.providerData[0].displayName!, user.providerData[0].email!]);
    }, [user]);

    const fetchSubjects = async (grade: string) => {

        console.log("gradeint TOPICS: ", grade);
        const responsegrade = await require('../data/subjectsgeneralized.json');
        const datagrade = await JSON.parse(JSON.stringify(responsegrade));
        setAvailableSubjects(datagrade[grade] || []);

    };
    // grade from url but only the integer part
    var gradefromurlshort = "";
    if (props.gradefromurl !== null) {
        gradefromurlshort = props.gradefromurl.replace(/\D/g, '');

        if (gradefromurlshort === "") {
            gradefromurlshort = "Prep";
        }
    }
    // console.log("gradefromurlshort : ", gradefromurlshort);
    useEffect(() => {
        if (defaultTopicFromTextMatTopic !== null) {
            setMatTopic(defaultTopicFromTextMatTopic);
            console.log("fromurl : ", subjectfromurl, gradefromurl);
        } else {
            setMatTopic("");
            console.log("defaultTopicFromTextMatTopic is null");
        }
    }, [defaultTopicFromTextMatTopic, gradefromurl, subjectfromurl]);
    //fetch available subjects for selected grade
    useEffect(() => {
        if (grade !== '') {
            fetchSubjects(grade).then((subjects) => {
                console.log('Subjects fetched successfully: ', subjects);
            });

        }

        else {
            setGrade(gradefromurlshort);
            console.log('No grade selected');

        }
    }, [grade, gradefromurl, gradefromurlshort, props.actualGradefromurl]);
    const materialTypeExtensions = {
        "Doc": ['doc', 'docx', 'odt', 'txt', 'rtf'],
        "Sheet": ['xls', 'xlsx', 'ods', 'csv', 'tsv', 'tab', 'xlsm', 'xlt', 'xltx', 'xltm', 'ods', 'fods'],
        "PDF": ['pdf'],
        "Book": ['epub', 'mobi', 'azw', 'azw3', 'azw4', 'prc', 'azw1', 'azw6', 'azw8'],
        "Image": ['jpg', 'jpeg', 'png', 'gif', 'svg', 'bmp', 'tiff', 'webp', 'ico', 'jfif', 'pjpeg', 'pjp', 'avif', 'apng', 'bmp', 'heif', 'heic'],
        "Video": ['mp4', 'avi', 'mkv', 'mov', 'flv', 'wmv', 'webm', 'm4v', '3gp', '3g2', 'mpg', 'mpeg', 'm2v', 'm4v', 'svi', '3gpp', '3gpp2', 'mxf', 'roq', 'nsv', 'flv', 'f4v', 'f4p', 'f4a', 'f4b'],
        "Audio": ['mp3', 'wav', 'ogg', 'flac', 'aac', 'wma', 'm4a', 'webm'],
        "Archive": ['zip', 'rar', '7z', 'tar'],
    };
    var [fileUpload, setFileUpload] = useState<File | null>(null); //FileList | 
    useEffect(() => {
        if (fileUpload !== null) {
            if (materialName === "" || materialName === null) {
                setMaterialName(fileUpload.name as string);
            }
        }
    }, [fileUpload, setFileUpload, materialName]);
    useEffect(() => {
        if (fileUpload !== null) {
            if (materialName === "" || materialName === null) {
                setMaterialName(fileUpload.name as string);
            }
            const fileExtension = fileUpload.name.split('.').pop() as string;
            let derivedMatType = '';
            for (const [type, extensions] of Object.entries(materialTypeExtensions)) {
                if (extensions.includes(fileExtension)) {
                    derivedMatType = type;
                    break;
                } else { derivedMatType = 'Other' }
            }
            setMaterialType(derivedMatType);
        } else { setMaterialType(''); }
    }, [fileUpload, materialName]);

    var filedownloadurl: string | null = null;
    const handleFileUpload = async (e: React.FormEvent) => {
        if (fileUpload == null) return;
        const materialPath = "materials/" + materialtype + "s/" + grade + "/" + subject + "/" + matTopic;
        const tsnow = Timestamp.now();
        const matNameInStorage = materialName + tsnow;
        await uploadFile(fileUpload, matNameInStorage, materialPath).then((file) => {
            if (file === undefined) return null;
            getDownloadURL(ref(storage, materialPath + "/" + matNameInStorage)).then(filedownloadurlres => {
                filedownloadurl = filedownloadurlres;
                if (filedownloadurl !== null) {
                    console.log("File download URL acquired: ", filedownloadurl);
                    setMaterial(filedownloadurl);
                    material = filedownloadurl;
                }

                // Use filedownloadurl here
                console.log("file uploaded successfully", filedownloadurl);
                if (filedownloadurl !== null && subject !== '' && grade !== '' && materialtype !== "" && teacherInfo.length !== 0 && materialName !== '')
                    addMaterial(materialName, subject as Subject, grade, filedownloadurl, teacherInfo, materialtype, matTopic, tsnow)
                        .then((result) => {
                            if (result === 0) {
                                console.log('Material added successfully');
                                //Material added successfully => Reset form fields
                                // setMaterialType('');
                                setMaterial('');
                                setMaterialName('');
                                setSubject(subject as Subject);
                                setGrade(grade);
                                setFileUpload(null);
                                filedownloadurl = null;
                                setShowMatConfirmationElement(true);
                                setTimeout(() => {
                                    setShowMatConfirmationElement(false);
                                }, 3000);
                            } else if (result === 1) {
                                console.log("Teacher not found")
                            } else {
                                // Handle error
                                console.log('Error adding material 0');
                            }
                        })
                        .catch((error) => {
                            // Handle error
                            console.log('Error adding material 1', error.message);
                        });
            })

        }).catch((error) => {
            console.log("file upload error: ", error.message);
        });
    }

    useEffect(() => {
        if (materialtype === "") {
            if (material.includes("docs.google.com/document")) {
                setMaterialType("Doc");
            } else if (material.includes("docs.google.com/spreadsheets")) {
                setMaterialType("Sheet");
            } else if (material.includes("docs.google.com/presentation")) {
                setMaterialType("Slide");
            } else if (material.includes("docs.google.com/forms")) {
                setMaterialType("Form");
            } else {
                setMaterialType("Link");
            }
        }
        if (material.includes("youtu.be") || material.includes("youtube.com")) {
            console.log("material : ", material);
            const videoId = getYTVidId(material);
            console.log("videoId : ", videoId);
            const iframeSrc = 'https://www.youtube.com/embed/' + videoId + '';
            setMaterial(iframeSrc)
            setMaterialType("Youtube Video")
        }
        console.log("material : ", material);
        console.log("materialtype : ", materialtype);
    }, [materialName]);
    function getYTVidId(youtubeurl: string) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = youtubeurl.match(regExp);

        if (match && match[2].length === 11)
            return match[2]
        else {
            const regexp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
            const copiedUrlEmbedId = regexp.exec(youtubeurl);
            return copiedUrlEmbedId;
        }
    }

    //on add material button click => form submit => onSubmit => handleMaterialSubmit => addMaterial
    const handleMaterialSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        console.log("materialtype : ", materialtype);
        if (materialtype === "") {
            if (material.includes("docs.google.com/document")) {
                setMaterialType("Doc");
            } else if (material.includes("docs.google.com/spreadsheets")) {
                setMaterialType("Sheet");
            } else if (material.includes("docs.google.com/presentation")) {
                setMaterialType("Slide");
            } else if (material.includes("docs.google.com/forms")) {
                setMaterialType("Form");
            } else {
                setMaterialType("Link");
            }
        }
        console.log("material : ", material);
        console.log("subject : ", subject);
        console.log("grade : ", grade);
        console.log("materialtype : ", materialtype);
        console.log("teacherInfo : ", teacherInfo);
        console.log("matTopic : ", matTopic);
        console.log("materialName : ", materialName);
        console.log("fileUpload : ", fileUpload);
        console.log("filedownloadurl : ", filedownloadurl);

        if (subject !== '' && material !== '' && grade !== '' && materialtype !== "" && teacherInfo.length !== 0) {
            if (material.includes("youtu.be") || material.includes("youtube.com")) {
                const videoId = getYTVidId(material);
                console.log("videoId : ", videoId);
                const iframeSrc = 'https://www.youtube.com/embed/' + videoId + '';
                await setMaterial(iframeSrc)
                setMaterialType("Youtube Video")
            }
            const tsnow = Timestamp.now();
            //burada grade i şimdilik ben ekledim siz çekip koyarsınız
            addMaterial(materialName, subject, grade, material, teacherInfo!, materialtype, matTopic, tsnow)
                .then((result) => {
                    if (result === 0) {
                        console.log('Material added successfully : ' + material);
                        //Material added successfully => Reset form fields
                        // setMaterialType('');
                        setMaterial('');
                        setMaterialName('');
                        setMaterialType('');
                        setSubject(subject as Subject);
                        setGrade(grade);
                        setFileUpload(null);
                        filedownloadurl = null;
                        setShowMatConfirmationElement(true);
                        setTimeout(() => {
                            setShowMatConfirmationElement(false);
                        }, 2000);
                    } else if (result === 1) {
                        console.log("Teacher not found")
                    } else {
                        // Handle error
                        console.log('Error adding material 0');
                    }
                })
                .catch((error) => {
                    // Handle error
                    console.log('Error adding material 1', error.message);
                });
        } else {
            console.log('Missing fields: You must fill in all required fields to add a material');
        }
    };

    // useEffect(() => {
    //     var acct = getGAPIToken();
    //     acct.then((result) => {setGapiToken(result); console.log("gapi token : ", result);});
    // } , []);

    const [openPicker, authResponse] = useDrivePicker();
    const [data, setData] = useState<PickerCallback | null>(null);

    //use authresponse to get token and store it in localstorage for future use (max 1 hour)
    async function getGAPITokenAuthResponse() {
        if (localStorage.getItem("gapi_tokenrevoke")) {
            if (Date.now() < parseInt(localStorage.getItem("gapi_tokenrevoke")!)) return localStorage.getItem("gapi_accesstoken")!;
        }
        if (authResponse) {
            console.log("authResponse : ", authResponse);
            if (authResponse.access_token) {
                console.log("authResponse.access_token : ", authResponse.access_token);
                localStorage.setItem("gapi_accesstoken", authResponse.access_token);
                setGapiToken(authResponse.access_token);
                localStorage.setItem("gapi_tokenrevoke", (Date.now() + authResponse.expires_in * 1000).toString());
            }
        }
    }
    useEffect(() => {
        //reset authresponse after getting token or token expires
        if (authResponse) {
            getGAPITokenAuthResponse().then((result) => {
                console.log("gapi token result: ", result);
                setGapiToken(result!);
            });
            console.log("gapi token new : ", localStorage.getItem("gapi_accesstoken"));
        }
    }, [authResponse]);
    useEffect(() => {
        getGAPITokenAuthResponse().then((result) => {
            console.log("gapi token result: ", result);
            setGapiToken(result!);
        });
        console.log("gapi token : ", localStorage.getItem("gapi_accesstoken"));
    }, []);

    const handleOpenPicker = () => {
        openPicker({
            clientId: "1085001691243-ug01tm2j5kkirg79m76ctbt1sl1q6q3j.apps.googleusercontent.com",
            developerKey: "AIzaSyB8z8_wiymQEAi6B5aR5xa0HRonLywsS0Y",
            viewId: "DOCS",
            token: gapiToken ? gapiToken : authResponse?.access_token,
            showUploadView: true,
            showUploadFolders: true,
            supportDrives: true,
            multiselect: true,
            callbackFunction: (data) => {
                console.log('data : ', data);
                console.log('data.action : ', data.action);
                if (data.action === 'cancel') {
                    console.log('User clicked cancel/close button');
                }
                if (data.action === 'loaded') {
                    console.log('Picker loaded successfully');
                }
                if (data.action === 'authed') {
                    console.log('User authorized app successfully');
                }
                if (data.action === 'picked') {
                    console.log('OAuth Token: ', authResponse?.access_token);
                    console.log('User selected files', data.docs);
                    setData(data);
                    if (data.docs?.map((doc) => (doc.uploadState === "success")).every(Boolean)) {
                        setMaterialName("");
                        setMaterialType("");
                        setMaterial("");
                        console.log("all files uploaded successfully");
                        setData(data);

                        if (data.docs.length > 1) {
                            const materialsArray: string[] = [];
                            const materialNamesArray: string[] = [];
                            const materialTypesArray: string[] = [];

                            for (const doc of data.docs) {
                                let embedUrl = doc.embedUrl;

                                if (!embedUrl && doc.url) {
                                    embedUrl = doc.url.replace("view", "preview");
                                }

                                if (embedUrl) {
                                    materialsArray.push(embedUrl);
                                    materialNamesArray.push(doc.name);

                                    const fileExtension = doc.name.split('.').pop()?.toLowerCase() as string;
                                    let derivedMatType = 'Other';

                                    for (const [type, extensions] of Object.entries(materialTypeExtensions)) {
                                        if (extensions.includes(fileExtension)) {
                                            derivedMatType = type;
                                            break;
                                        }
                                    }

                                    if (derivedMatType === 'Other') {
                                        if (embedUrl.includes("docs.google.com/document")) {
                                            derivedMatType = "Doc";
                                        } else if (embedUrl.includes("docs.google.com/spreadsheets")) {
                                            derivedMatType = "Sheet";
                                        } else if (embedUrl.includes("docs.google.com/presentation")) {
                                            derivedMatType = "Slide";
                                        } else if (embedUrl.includes("docs.google.com/forms")) {
                                            derivedMatType = "Form";
                                        }
                                    }

                                    materialTypesArray.push(derivedMatType + " (Drive)");
                                }
                            }

                            setMaterials((prevMaterials) => [...prevMaterials, ...materialsArray]);
                            setMaterialNames((prevMaterialNames) => [...prevMaterialNames, ...materialNamesArray]);
                            setMaterialTypes((prevMaterialTypes) => [...prevMaterialTypes, ...materialTypesArray]);
                        } else if (data.docs.length === 1) {
                            const singleDoc = data.docs[0];
                            let embedUrl = singleDoc.embedUrl;

                            if (!embedUrl && singleDoc.url) {
                                embedUrl = singleDoc.url.replace("view", "preview");
                            }

                            if (embedUrl) {
                                setMaterial(embedUrl);
                                setMaterialName(singleDoc.name);

                                const fileExtension = singleDoc.name.split('.').pop()?.toLowerCase() as string;
                                let derivedMatType = 'Other';

                                for (const [type, extensions] of Object.entries(materialTypeExtensions)) {
                                    if (extensions.includes(fileExtension)) {
                                        derivedMatType = type;
                                        break;
                                    }
                                }

                                if (derivedMatType === 'Other') {
                                    if (embedUrl.includes("docs.google.com/document")) {
                                        derivedMatType = "Doc";
                                    } else if (embedUrl.includes("docs.google.com/spreadsheets")) {
                                        derivedMatType = "Sheet";
                                    } else if (embedUrl.includes("docs.google.com/presentation")) {
                                        derivedMatType = "Slide";
                                    } else if (embedUrl.includes("docs.google.com/forms")) {
                                        derivedMatType = "Form";
                                    }
                                }

                                setMaterialType(derivedMatType + " from Drive");
                            }
                        }
                    }
                }
            },
        });
    }
    // GOOGLE DRIVE PICKER COPY PASTE END
    // DRIVE ŞEYLERİ BURDAAAAA ÇALIŞIYOOOOOOO
    var [materials, setMaterials] = useState<string[]>([]);
    var [materialNames, setMaterialNames] = useState<string[]>([]);
    var [materialTypes, setMaterialTypes] = useState<string[]>([]);
    useEffect(() => {
        if (data) {
            setMaterialName("");
            setMaterialType("");
            console.log("doc : ", data.docs?.map((doc) => doc));
            console.log("doc download url : ", data.docs.map((doc) => doc.url));
            if (data.docs.length > 1) {
                if (data.docs.map((doc) => doc.embedUrl).every(Boolean)) {
                    materials = (data.docs.map((doc) => doc.embedUrl)
                    );
                } else {
                    materials = (data.docs.map((doc) => doc.url.replace("view", 'preview'))
                    );
                }
                materialNames = (data.docs.map((doc) => doc.name)
                );

                const fileExtensions = data.docs.map((doc) =>
                    doc.name.split('.').pop()?.toLowerCase()
                ) as string[];

                var derivedMatTypes = fileExtensions.map((fileExtension) => {
                    for (const [type, extensions] of Object.entries(materialTypeExtensions)) {
                        if (extensions.includes(fileExtension)) {
                            return type;
                        }
                    }
                    return 'Other';
                });

                materialTypes = derivedMatTypes?.map((type) => type + " from Drive");
            } else {
                const singleDoc = data.docs[0];
                if (singleDoc.embedUrl) {
                    setMaterial(singleDoc.embedUrl);
                } else {
                    setMaterial(singleDoc.url.replace("view", 'preview'));
                }
                const fileExtension = singleDoc.name.split('.').pop()?.toLowerCase() as string;

                let derivedMatType = 'Other';
                for (const [type, extensions] of Object.entries(materialTypeExtensions)) {
                    if (extensions.includes(fileExtension)) {
                        derivedMatType = type;
                        break;
                    }
                }

                setMaterialName(singleDoc.name);
                setMaterialType(derivedMatType + " from Drive");
            }

            if (
                subject !== '' &&
                grade !== '' &&
                teacherInfo.length !== 0 &&
                materialNames.length !== 0 &&
                materials.length !== 0 &&
                materialTypes.length !== 0
            ) {
                console.log("all there, running addMultipleMaterials", materialNames, materials, materialTypes);
                var tsnow = Timestamp.now();
                addMultipleMaterials(
                    materialNames,
                    subject as Subject,
                    grade,
                    materials,
                    teacherInfo,
                    materialTypes,
                    matTopic,
                    tsnow
                ).then((result) => {
                    if (result === 0) {
                        console.log('all Materials added successfully');
                        setMaterials([]);
                        setMaterialNames([]);
                        setMaterialTypes([]);
                        materials = [];
                        materialNames = [];
                        materialTypes = [];
                        setSubject(subject as Subject);
                        setGrade(grade);
                        setFileUpload(null);
                        filedownloadurl = null;
                        setShowMatConfirmationElement(true);
                        setTimeout(() => {
                            setShowMatConfirmationElement(false);
                        }, 5000);

                    } else if (result === 1) {
                        console.log("error adding materials");
                    }
                });
            } else {
                console.log('Missing fields: You must fill in all required fields to add a material');
                console.log("fields : ", materials, materialNames, materialTypes);
            }
        }
        console.log("data : ", data);
        console.log("materials : ", materials);
        console.log("materialNames : ", materialNames);
        console.log("materialTypes : ", materialTypes);
        console.log("material : ", material);
        console.log("materialName : ", materialName);
        console.log("materialtype : ", materialtype);
    }, [data]);

    useEffect(() => {
        setMaterials([]);
        setMaterialNames([]);
        setMaterialTypes([]);
        materials = [];
        materialNames = [];
        materialTypes = [];
    }, []);

    const [showMatConfirmationElement, setShowMatConfirmationElement] = useState(false);
    const [isMaterialContentVisible, setIsMaterialContentVisible] = useState(props.isOpenByDefault || false);
    const handleToggleMaterialContent = () => {
        setIsMaterialContentVisible(!isMaterialContentVisible);
    };
    return (
        <div className="admin-widget">
            <div className="admin-widget-title" onClick={handleToggleMaterialContent}>
                <b>📚 {t("admin.add-material")}</b> ({subjectfromurl + ", " + gradefromurl})
            </div>
            <div className={`admin-widget-content ${isMaterialContentVisible ? 'visible' : ''}`}>
                <hr />
                <div className="admin-subwidget">
                    <form onSubmit={handleMaterialSubmit}>
                        <div id="admin-mat-options">
                            <div className="admin-mat-option">
                                <label><b>Google Drive</b></label>
                                <button className="admin-button hover-up"
                                    onClick={() => {
                                        setFileUpload(null); setMaterial(""); setMaterialName(""); setMaterialType("");
                                        setMaterials([]); setMaterialNames([]); setMaterialTypes([]);
                                        fileUpload = null;
                                        handleOpenPicker()
                                    }}><AddToDriveIcon /><span>{t("admin.add")}</span></button>
                            </div>
                            <div className="admin-mat-option">
                                <label><b>{t("admin.link")}</b></label>
                                <input className="admin-input" type="url" name="matcontent" id="link-select" value={material} defaultValue={material}
                                    onChange={(e) => setMaterial(e.target.value)} placeholder={t("admin.pastelink")} />
                            </div>
                            <div className="admin-mat-option">
                                <label><b>{t("admin.file")}</b></label>
                                <label id="fake-admin-button" className="hover-up" htmlFor="admin-file-input"><b>{t("admin.add")}</b></label>
                                <input type="file" id="admin-file-input"
                                    onChange={(e) => {
                                        if (e.target.files) {
                                            setFileUpload(null); setMaterial(""); setMaterialName(""); setMaterialType("");
                                            setMaterials([]); setMaterialNames([]); setMaterialTypes([]);
                                            setFileUpload(e.target.files[0])
                                        }
                                    }} />
                            </div>

                        </div>
                        <hr />
                        {/* GRADE BE SUBJECT SEÇİMİ COMMENTLANDI, ADMIN İÇİN EKLENECEK. ONUN YERINE SADECE TOPIC SEÇİMİ. */}

                        {/* <div className="admin-input-wrapper">
                            <label htmlFor="class"><b>Seviye</b> / Niveau</label>
                            <select className="admin-dropdown" name="class" id="class-select" required defaultValue={gradefromurl as string}
                                onChange={(e) => setGrade(e.target.value)}>
                                <option value="Prep">{t("subjectpage.prep")}</option>
                                <option value="9">9{t("subjectpage.thgrader")}</option>
                                <option value="10">10{t("subjectpage.thgrader")}</option>
                                <option value="11">11{t("subjectpage.thgrader")}</option>
                                <option value="12">12{t("subjectpage.thgrader")}</option>
                            </select>
                        </div>
                        <div className="admin-input-wrapper">
                            <label htmlFor="subject"><b>Ders</b> / Matière</label>
                            {grade !== '' &&
                                <select className="admin-dropdown" name="subject" id="subject-select" required defaultValue={subjectfromurl as string}
                                    onChange={(e) => (setSubject(e.target.value as Subject), console.log(e.target.value))}>
                                    {availableSubjects.map((subject) => (
                                        <option key={subject} value={subject.replace(/\s/g, '').toLowerCase()}>
                                            {subject}
                                        </option>
                                    ))}
                                </select>}
                        </div> */}


                        <div className="admin-input-wrapper">
                            <label htmlFor="matname"><b>{t("admin.title")}</b></label>
                            {/* <button onClick={handleFileUpload}>Upload File</button> */}
                            <input className="admin-input" type="text" name="matname" id="matname" value={materialName}
                                onChange={(e) => setMaterialName(e.target.value)} placeholder={t("admin.matname")} required />
                        </div>
                        <br />
                        <button className={`
                                            ${showMatConfirmationElement ? 'admin-button-confirm' : ''}
                                            admin-button
                                            colored-button
                                            hover-up
                                                `}
                            type="submit"
                            onClick={handleFileUpload}>
                            <SendIcon />
                            <span>{showMatConfirmationElement ? t("admin.mat-published") :
                                t("admin.mat-publish")}</span>
                        </button>
                    </form>
                    {/* {showMatConfirmationElement ? (
                        <div className="multiple-material-upload-confirmation"
                            style={{
                                margin: '10px 0',
                                height: "30px",
                                background: "dark-grey",
                                color: "green",
                                fontSize: "18px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                fontFamily: "roboto",
                                opacity: showMatConfirmationElement ? 1 : 0
                            }}
                        >
                            Material(s) Added Successfully.
                        </div>
                    ) : (
                        <div></div>
                    )} */}
                </div>
            </div>
        </div>
    );
}

export default MaterialAdd;