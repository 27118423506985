import React, { useEffect, useState } from "react";
import {
  getAuth,
  User,
} from "firebase/auth";
import { redirect } from "react-router-dom";
import { FirebaseError, } from "firebase/app";
import LoginIcon from '@mui/icons-material/Login';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useAuth, } from "../contexts/AuthContext"
import '../styles/mainpage-styles.css';
import '../styles/login.css';
import { app } from "../configs/firebaseconfigs";

export const auth = getAuth(app)

function AuthPage({ user }: { user: User | null }) {
  const { googleSignIn } = useAuth();

  const handleSignIn = async () => {
    try {
      await googleSignIn();

    } catch (error) {
      if ((error as FirebaseError).code === "auth/cancelled-popup-request") {
        console.log('Cancelled popup request');
        redirect("/authpage");
      } else if ((error as FirebaseError).code === "auth/popup-closed-by-user") {
        console.log('Popup closed by user');
        redirect("/authpage");
      } else if ((error as FirebaseError).code === "auth/popup-blocked") {
        console.log('Popup blocked');
        redirect("/authpage");
      }
      else {
        // Handle other errors
        console.log(error);
      }
    }
  };

  // Call handleSignIn when needed

  return (
    <>
      <div className="page-wrapper login-body">
        <div className="login-greeting"><b>👋 <br />Tekrar hoş geldin!</b> <br /> Rebonjour !</div>
        <p className="login-desc"><s>Metaclass içeriklerine ulaşmak için lütfen <u>@sj.k12.tr uzantılı okul mailin ile</u> giriş yap. <br />
          Pour accéder au contenu de Metaclass, veuillez vous connecter <u>avec l'adresse mail @sj.k12.tr</u>.</s>
        </p>
        <div className="login-disclaimer">
          <div><InfoOutlinedIcon /></div>
          <span>
            SJ hesaplarıyla giriş yapma konusunda IT ekibiyle çalışmalarımızı sürdürüyoruz. Lütfen kişisel mail adreslerinizle giriş yapınız. <br />
            <i>Nous continuons à travailler avec l'équipe TI sur la connexion avec les comptes SJ. Veuillez vous identifier avec votre e-mail personnel.</i>
          </span>
        </div>

        <button className="generic-button hover-up colored-button" onClick={handleSignIn}><LoginIcon /><span>Giriş Yap / S'identifier</span></button>
      </div>
    </>
  );
};

// return null; // Render nothing on this page
export default AuthPage;
