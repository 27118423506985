import React, { useState, useEffect } from 'react';
import '../styles/mainpage-styles.css';
import LogoutIcon from '@mui/icons-material/Logout';
import { signOut, User } from 'firebase/auth';
import AuthPage, { auth } from './authpage';
import { Link } from 'react-router-dom';
import { AuthProvider } from '../contexts/AuthContext';
import { getAlertsByImpAndLessonandgrade } from '../serverlib/db';
import { Alert, AppUser } from '../serverlib/util-types';
import { getAppUser } from "../serverlib/db";
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../components/languageSelector';
function MainPage({ user }: { user: User | null }) {
    const [t, i18n] = useTranslation("common");
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'tr';
    useEffect(() => {
        i18n.changeLanguage(selectedLanguage);
        console.log("chosen language : ", i18n.language);
    }, [selectedLanguage, i18n]);

    var [appUser, setAppUser] = useState<AppUser | null>(null);
    const [subjects, setSubjects] = useState<string[]>([]);
    const [grade, setGrade] = useState(localStorage.getItem('selectedGrade') || 'subjectsPrep'); // get selectedgrade from localstorage if exists. else default to prepSubjects.
    const [alertList1, setAlertList1] = useState<Alert[]>([]);
    const [alertList2, setAlertList2] = useState<Alert[]>([]);
    const [subjectpageSession, setSubjectpageSession] = useState(localStorage.getItem('subjectpageSession') || '');
    //in subjectpage.tsx:
    //save the topc, lesson and grade to the local storage to be reset after 40 minutes. (the user may leave the page, so dont use session storage as it will be reset after the tab is closed, and  dont use settimeout as the user may leave the page and come back after 40 minutes)
    // one signle object.
    //   useEffect(() => {
    //     localStorage.setItem("subjectpageSession", JSON.stringify({ subject: subjectbasicform, grade: grade, topic: textMatTopic , resetByTime: new Date().getTime() + 2400000 })); //40 minutes
    //   }, [subjectbasicform, grade, textMatTopic]);
    //redirect user based on localstorage subjectpageSession
    useEffect(() => {
        if (subjectpageSession !== '') {
            const session = JSON.parse(subjectpageSession);
            if (session.resetByTime > new Date().getTime()) {
                localStorage.setItem("subjectpageSession", '');
                window.location.href = `/subjectpage/${session.grade}/${session.subject}?topic=${session.topic}`;
            }
        }
    }, []);
    // Function to handle grade change
    const handleGradeChange = (newGrade: string) => {
        setGrade(newGrade);
        localStorage.setItem('selectedGrade', newGrade); // Store selected grade in localStorage
    };
    // Fetch Subjects from Firestore and map them as subjectcards based on grade selected
    useEffect(() => {
        console.log("grade in main : ", grade);
        const fetchSubjects = async () => {
            const response = await require('../data/subjects.json');
            const data = await JSON.parse(JSON.stringify(response));
            setSubjects(data[grade] || []);
        };

        fetchSubjects();
    }, [grade]);

    useEffect(() => {
        const fetchAlerts = async () => {
            const importance1Alerts = await getAlertsByImpAndLessonandgrade(1);
            setAlertList1(importance1Alerts as Alert[]);
        };

        fetchAlerts();
    }, []);

    // Fetch importance 2 alerts when the component mounts
    useEffect(() => {
        const fetchAlerts = async () => {
            const importance2Alerts = await getAlertsByImpAndLessonandgrade(2);
            setAlertList2(importance2Alerts as Alert[]);
        };

        fetchAlerts();
    }, []);

    useEffect(() => {
        getAppUser(user?.uid!).then(AppUser => {
            if (AppUser !== null) {
                setAppUser(AppUser as AppUser);
                console.log("user accesslevel(s) : ", AppUser?.accesslevel);
            }
        });
    }, [user]);
    const [loadingAppuser, setLoading] = useState(true);
    useEffect(() => {
        if (appUser !== null) {
            setLoading(false);
        }
    }, [appUser]);
    const [isAnnouncementsContentVisible, setIsAnnouncementsContentVisible] = useState(false);
    function handleToggleAnnouncementsContent() {
        setIsAnnouncementsContentVisible(!isAnnouncementsContentVisible);
    }
    if (user && !loadingAppuser) {
        console.log(localStorage.getItem('selectedGrade'));
        console.log(user.uid);
        console.log(user.providerData[0].email);
        return (
            <div className="page-wrapper">
                <title>Metaclass</title>
                <meta name='description' content='Education but cooler, made my SJ students.' />

                <div className="header-frost"></div>
                <div className="header">
                    <div className="header-left">
                        <a href="/"><img style={{ height: "100%" }} src={require('../img/sj-white.png')} alt="School Logo" /></a>
                        <div className="vertical-line"></div>
                        {user && <p className="greeting">
                            <span className="greeting-sentence">{t("mainpage.greeting")},</span>
                            <span>{user?.providerData[0].displayName}</span>
                        </p>}
                        {/* <GroupList user={user}/> */}
                    </div>
                    <button className="generic-button hover-up colored-button" onClick={() => { signOut(auth) }}><LogoutIcon /></button>
                </div>

                <div className="quick-access-wrapper">
                    <a className="quick-access-card hover-up" href="https://sj.k12.tr/" target="_blank" rel="noreferrer">sj.k12.tr</a>
                    <a className="quick-access-card hover-up" href="https://e-okul.meb.gov.tr/" target="_blank" rel="noreferrer">e-Okul</a>
                    <a className="quick-access-card hover-up" href="https://velibilgi.sj.k12.tr/" target="_blank" rel="noreferrer">Okulsis</a>
                    <a className="quick-access-card hover-up" href="https://sj.k12.tr/wp-content/uploads/pdf/yemek-menusu.pdf" target="_blank" rel="noreferrer">Menü</a>
                    <a className="quick-access-card hover-up" href="https://2089999n.esidoc.fr/site/bienvenue-sur-le-portail-du-cdi" target="_blank" rel="noreferrer">Médiathèque</a>
                    <a className="quick-access-card hover-up" href="https://istanbulsj.atlassian.net/servicedesk/" target="_blank" rel="noreferrer">JIRA</a>
                    <a className="quick-access-card hover-up" href="https://moodle.sj.k12.tr/" target="_blank" rel="noreferrer">Moodle</a>
                </div>

                <div className="announcements-wrapper">

                    <div className="section-title" style={{ cursor: "pointer" }} onClick={handleToggleAnnouncementsContent}>📢 <b>{t("mainpage.announcements")}</b> ({alertList1.length + alertList2.length})
                        {!isAnnouncementsContentVisible && <i> &nbsp; {t("misc.expand")}</i>}
                    </div>
                    <div className={`announcements-content ${isAnnouncementsContentVisible ? 'visible' : ''}`}>
                        <div className="announcements-tint"></div>
                        {alertList1.length > 0 && (
                            <div id="alert-wrapper">
                                {alertList1.map((alert, index) => (
                                    <div key={index}>
                                        <div className="alert-item" key={alert.title}>
                                            <h2>{alert.title}
                                                <span>• {alert.author}</span>
                                            </h2>
                                            <p>{alert.message}</p>
                                        </div>
                                        <hr />
                                    </div>
                                ))}
                            </div>
                        )}
                        {alertList2.length > 0 && (
                            <div id="announcement-wrapper">
                                {alertList2.map((alert, index) => (
                                    <div key={index}>
                                        <div className="announcement-item" key={alert.title}>
                                            <h2>{alert.title}
                                                <span>• {alert.author}</span>
                                            </h2>
                                            <p>{alert.message}</p>
                                        </div>
                                        <hr />
                                    </div>
                                ))}
                            </div>
                        )}
                        <div style={{ height: "12px" }}></div>
                    </div>
                </div>
                {/* Map subjects, set logic later for determining level and mapping based on level */}
                {/* id'leri dersin tam adının küçük harfle yazılmış ve boşluksuz, noktalamasız hali. özel karakterler var. örnek : "görselsanatlar" */}

                <form className="radio-main" name='grade' action="grade" id="radio-main">
                    <p><b>{t("mainpage.gradepick")}</b></p>
                    <div className="radio-main-options">
                        <input type="radio" id="hz" name="grade" value="subjectsPrep"
                            checked={grade === 'subjectsPrep'}
                            onChange={(e) => handleGradeChange(e.target.value)} />
                        <label className="hover-up" id="hz-label" htmlFor="hz">Hz</label>

                        <input type="radio" id="c9" name="grade" value="subjects9"
                            checked={grade === 'subjects9'}
                            onChange={(e) => handleGradeChange(e.target.value)} />
                        <label className="hover-up" id="c9-label" htmlFor="c9">9</label>

                        <input type="radio" id="c10" name="grade" value="subjects10"
                            checked={grade === 'subjects10'}
                            onChange={(e) => handleGradeChange(e.target.value)} />
                        <label className="hover-up" id="c10-label" htmlFor="c10">10</label>

                        <input type="radio" id="c11mf" name="grade" value="subjects11mf"
                            checked={grade === 'subjects11mf'}
                            onChange={(e) => handleGradeChange(e.target.value)} />
                        <label className="hover-up" id="c11mf-label" htmlFor="c11mf">11 Fen</label>

                        <input type="radio" id="c11tm" name="grade" value="subjects11tm"
                            checked={grade === 'subjects11tm'}
                            onChange={(e) => handleGradeChange(e.target.value)} />
                        <label className="hover-up" id="c11tm-label" htmlFor="c11tm">11 TM</label>

                        <input type="radio" id="c12mf" name="grade" value="subjects12mf"
                            checked={grade === 'subjects12mf'}
                            onChange={(e) => handleGradeChange(e.target.value)} />
                        <label className="hover-up" id="c12mf-label" htmlFor="c12mf">12 Fen</label>

                        <input type="radio" id="c12tm" name="grade" value="subjects12tm"
                            checked={grade === 'subjects12tm'}
                            onChange={(e) => handleGradeChange(e.target.value)} />
                        <label className="hover-up" id="c12tm-label" htmlFor="c12tm">12 TM</label>

                        <input type="radio" id="c12ts" name="grade" value="subjects12ts"
                            checked={grade === 'subjects12ts'}
                            onChange={(e) => handleGradeChange(e.target.value)} />
                        <label className="hover-up" id="c12ts-label" htmlFor="c12ts">12 TS</label>
                    </div>

                </form>
                <div className="subjects-widget-new">
                    {subjects.map((subject, index) => (
                        <Link to={`/subjectpage/${grade}/${subject.replace(/\s/g, '').toLowerCase()}`} className='subject-card hover-up' key={index}>
                            <div className="subject-icon" id={subject.replace(/\s/g, '').toLowerCase()}></div>
                            <h1>{subject}</h1>
                        </Link>
                    ))}
                </div>
                {/* <div className="subjects-widget">
                    {subjects.map((subject, index) => (
                        <Link to={`/subjectpage/${grade}/${subject.replace(/\s/g, '').toLowerCase()}`} className='subject-card' key={index}>
                            <div className="subject-card hover-up" id={subject.replace(/\s/g, '').toLowerCase()}>
                                <h1>{subject}</h1>
                            </div>
                        </Link>
                    ))}
                </div> */}

                <div className="footer">
                    <p>©2024 <a href="/team">Metaclass</a></p>
                    <span className="footer-links">
                        {/* {(appUser?.isAdmin) && (
              <>
                <a href="/texteditor" rel="noreferrer">{t("footer.texteditor")}</a>
                <span className="link-dot">•</span>
              </>
            )}

            {(appUser?.accesslevel === "teacher") && (
              <>
                <a href="/myclasses" rel="noreferrer">{t("footer.myclasses")}</a>
                <span className="link-dot">•</span>
              </>
            )} */}

                        {(appUser?.isAdmin) && ( // || appUser?.accesslevel === "teacher" removed from conditions: teachers should use their subject's page to edit.
                            <>
                                <a href="/admin" rel="noreferrer">{t("footer.admin")}</a>
                                <span className="link-dot">•</span>
                            </>
                        )}
                        <a href="https://forms.gle/1dXApjbXShd4mSZD8" target="_blank" rel="noreferrer">{t("footer.feedback")}</a>
                        <span className="link-dot">•</span> <LanguageSelector />

                    </span>
                </div>
            </div>

        );
    } else if (user && loadingAppuser) {
        return (
            <div
                style={{ color: "white", alignContent: "center", alignItems: "center", textAlign: "center", justifyContent: "center", display: "flex", height: "100vh", width: "100vw", fontSize: "24px" }}>
                <p>
                    <b>Yükleniyor...</b>
                    <br />
                    Chargement...
                </p>
            </div>
        )
    }
    else return <AuthProvider><AuthPage user={user} /> </AuthProvider>
}

export default MainPage;
