import React from 'react';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import "../styles/mainpage-styles.css";

interface GenericPdfDownloaderProps {
    rootElementId: string;
    downloadFileName: string;
}

const GenericPdfDownloader = (props: GenericPdfDownloaderProps) => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const downloaddate = [year, month, day].join('-');
    const CreatePDFfromHTML = () => {
        const input = document.getElementById(props.rootElementId);
        if (input !== null) {
            html2canvas(input)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const pdf = new jsPDF('p', 'mm', 'a4');
                    pdf.addImage(imgData, 'PNG', 0, 0, 211, 298);
                    pdf.save(props.downloadFileName + downloaddate + '.pdf');
                });
        }
    }

    return <button className="generic-button hover-up" onClick={CreatePDFfromHTML}><SaveAltIcon/>PDF</button>
}

export default GenericPdfDownloader;