import { useEffect , useState} from "react";
import { useTranslation } from "react-i18next";
import "../styles/mainpage-styles.css";
import LanguageIcon from '@mui/icons-material/Language';

const LanguageSelector = () => {
    const deviceLanguage = localStorage.getItem("deviceLanguage");
    const [language, setLanguage] = useState<string>(localStorage.getItem("selectedLanguage") as string);
    const { i18n } = useTranslation();
    useEffect(() => {
        if (localStorage.getItem("selectedLanguage") === null) {
            if ( deviceLanguage !== null && deviceLanguage in ["tr", "fr", "en"])
            localStorage.setItem("selectedLanguage", deviceLanguage!);
            setLanguage(deviceLanguage!);
        }
    } , [deviceLanguage]);
    useEffect(() => {
        localStorage.setItem("selectedLanguage", language);
        i18n.changeLanguage(language!);
        console.log("Language changed to : ", language, " from ", localStorage.getItem("selectedLanguage"), i18n.language);
    }, [i18n, language]);
    return (
        <div className="language-selector">
            <LanguageIcon />
            <select name="languageselect" id="languageselect" onChange={(e)=> 
                setLanguage(e.target.value)} value={localStorage.getItem("selectedLanguage") as string} >
                <option value="tr">Türkçe</option>
                <option value="fr">Français</option>
                <option value="en">English</option>
            </select>
        </div>
    )
};

export default LanguageSelector;