import { app } from '../configs/firebaseconfigs';
// import { initializeApp } from 'firebase/app';
import {Subject, Material, Alert, Topic, AppUser, Teacher} from './util-types';
// import Teacher from "./util-types"
import { collection, doc, addDoc, getDoc, getDocs, query, where, DocumentReference, onSnapshot, deleteDoc, updateDoc , setDoc, Query, Timestamp, runTransaction, initializeFirestore} from 'firebase/firestore';
import { persistentLocalCache, persistentMultipleTabManager , CACHE_SIZE_UNLIMITED} from 'firebase/firestore';
import { deleteFile } from './storage';
//import GoogleAuth from 'google-auth-library';
// import {google , drive_v3} from "googleapis" //bu baya bozuk lan
// import { queries } from '@testing-library/react';
// import { StringMappingType } from 'typescript';
//import  {auth, provider} from '../configs/firebaseconfigs'

// const app = initializeApp(firebaseConfig);

// Use multi-tab IndexedDb persistence.
export const db = initializeFirestore(app, 
    {
        localCache: persistentLocalCache(/*settings*/{
            // The default cache size threshold is 40 MB. Configure "cacheSizeBytes"
            cacheSizeBytes: CACHE_SIZE_UNLIMITED,
            tabManager: persistentMultipleTabManager()}),
        

    }
);
// export const db = getFirestore(app);
//enable offline persistence, caching for firestore queries
// const persistanceSettings = {synchronizeTabs: true, cacheSizeBytes: CACHE_SIZE_UNLIMITED} as PersistenceSettings
// enableIndexedDbPersistence(db, persistanceSettings).catch((err) => {
//     if (err.code == 'failed-precondition') {
//         console.log('Multiple tabs open, persistence can only be enabled in one tab at a a time.');
//     } else if (err.code == 'unimplemented') {
//         console.log('The current browser does not support all of the features required to enable persistence');
//     }
// })

async function addMaterial(name:string , subject: Subject, grade: String, content: string,  teacherInfo: string[], materialtype: string,topic: string, time: Timestamp) {

    if (materialtype === "Other from Drive") {
        if (content.includes("docs.google.com/document")) {
            materialtype = "Google Document"
        }
        if (content.includes("docs.google.com/presentation")) {
            materialtype = "Google Slide"
        }
        if (content.includes("docs.google.com/spreadsheets")) {
            materialtype = "Google Sheet"
        }
    }

    // const docRef = doc(db, "teachers", teacherInfo[0])
    var material = new Material(
        name,
        content,
        teacherInfo,
        materialtype,
        topic as any,
        time
    )

    await addDoc(collection(db,'materials/' + subject + '/' + grade), Object.assign({}, material));

    return 0
}

export const bulkDeleteMaterials = async (subject: Subject, grade: string, topic?: string, content?:string, name?:string) => {
    const collectionRef = collection(db, "materials/" + subject + "/" + grade);

    const q = query(collectionRef,
        where("name", "==", "name"),
        where("topic", "==", topic),
        
        );

    const qs = (await getDocs(q)).docs;
    if (qs.length === 0) {
            console.log("Error: Material not found.");
            return 1;
    } else if (qs.length >= 1) {
        for (var i = 0; i < qs.length; i++) {
                const docRef = qs[i].ref;
                await deleteDoc(docRef).then((r) => {
                    console.log("Material deleted successfully", r)
                })
            }
            return 0;
        }

}
//teacherInfo[0] is the uid of the teacher, teacherInfo[1] is the name of the teacher, teacherInfo[2] is the mail of the teacher
export const addMultipleMaterials = async (names: string[], subject: Subject, grade: string, contents: string[], teacherInfo: string[], materialtypes: string[], topic: string, time: Timestamp): Promise<number> => {
    // const docRef = doc(db, "users", teacherInfo[0]);
    console.log("Adding multiple materials with names:", names);
    console.log("Contents:", contents);
    console.log("Material types:", materialtypes);
    try {
      await runTransaction(db, async (transaction) => {
        for (var i = 0; i < names.length; i++) {
          var material = new Material(
            names[i],
            contents[i],
            teacherInfo,
            materialtypes[i],
            topic,
            time
          );
  
          // Generate a new document reference for each material
          const newMaterialDocRef = doc(
            collection(db, `materials/${subject}/${grade}`)
          );
  
          await transaction.set(newMaterialDocRef, Object.assign({}, material));
          console.log("Material added successfully : " , material);
        }
      });
  
      console.log("Multiple materials added successfully");
      return 0;
    } catch (err) {
      console.log("Error adding multiple materials: ", err);
      return 1;
    }
  };
// add onsnapshot for realtime updates, if theres a new material, add it to the list of materials and return the new list
async function getMaterials(subject: Subject, gradeint: string){
    try {
        const colRef = collection(db, 'materials', subject, gradeint);
        const docSnap = await getDocs(colRef);
        
        if (docSnap.docs.length > 0) {
            const data = docSnap.docs.map(doc => doc.data());
            return data as Material[];

        } else {
            console.log('No materials found');
            
        }
    } catch (error) {
        console.error('Error fetching Subjects:', error);
    }
      

}

async function editMaterial(subject: Subject, grade:string, mtype: string, content?: string ,topic?: string, name?: string, visible?: boolean, material?: Material) {
    const collectionRef = collection(db, "materials/" + subject + "/" + grade);
    console.log("editMaterial: ", collectionRef.path, subject, grade, "type: ", mtype, "content: ", content, "topic: ",topic, name, visible);

    var q = query(collectionRef,
        where("topic", "==", topic),
        where("materialtype", "==", mtype),
        // where("content", "==", content)
        );

    //if name is not null, add it to the query
    if (name !== null || name !== undefined || name !== "") {
        q = query(q, where("name", "==", name));
        console.log("name added to query", name, q)
    }

    const qs = (await getDocs(q)).docs;
    if (qs.length !== 1) {
        if (qs.length === 0) {
            console.log("Error: Material not found.");
            return "error-no-mat-found";
        } else {
            console.log("Error: Multiple matching materials.");
            return "error-multiple-mats-found";
        }
    }
    if (qs.length === 1) {
        //update material
        const docRef = qs[0].ref;
        await updateDoc(docRef, {content:content, topic:topic, visible:visible}).then(() => {
            console.log("Material updated successfully")
            
        })
        return "success"
    }
    const docref = doc(db, "materials/"+subject+"/"+grade, qs[0].id);

    if (content != null || topic != null) {
        await updateDoc(docref, {content:content, topic:topic})
    }
    else if (content == null) {
        await updateDoc(docref, {topic:topic})
    }
    else if (topic == null) {
        await updateDoc(docref, {content:content})
    }
    else { return "nope editmaterial" }
 }

async function delMaterial(materialToDel: Material, grade: string, subject: Subject, topic: string) {
    const collectionRef = collection(db, "materials/" + subject + "/" + grade);
    const materialPathInStorage = "materials/" + materialToDel.materialtype + "s/" + grade + "/" + subject + "/" + materialToDel.topic;
    const materialName = materialToDel.name + materialToDel.time;
    const q = query(collectionRef,
        where("name", "==", materialToDel.name),
        where("content", "==", materialToDel.content),
        // where("teacherinfo", "==", materialToDel.teacherinfo),
        // where("materialtype", "==", materialToDel.materialtype),
        where("topic", "==", topic),
        where("time", "==", materialToDel.time)
    );

    const qs = (await getDocs(q)).docs;
    if (qs.length !== 1) {
        if (qs.length === 0) {
            console.log("Error: Material not found.");
            return 1;
        } else {
            console.log("Error: Multiple matching materials.");
            return 1;
        }
    }
    const docRef = qs[0].ref;
    await deleteDoc(docRef).then((r) => {
        console.log("Material deleted successfully")
        deleteFile(materialName, materialPathInStorage).then((r2) => {
            console.log("File deleted successfully")
        })
    } )
}

async function delAlert(alert: Alert) {
    const collectionRef = collection(db, "alerts"); // Remove " + Alert" here
    const q = query(collectionRef,
        where("message", "==", alert.message),
        where("author", "==", alert.author),
        where("title", "==", alert.title),
        where("importance", "==", alert.importance)
    );

    const qs = (await getDocs(q)).docs;
    if (qs.length !== 1) {
        if (qs.length === 0) {
            console.log("Error: Alert not found.");
            return 1;
        } else if (qs.length > 1){
        console.log("Error: multiple matching alerts.");
        // delete all matching alerts
        for (var i = 0; i < qs.length; i++) {
            const docRef = qs[i].ref;
            await deleteDoc(docRef);
        }
        return qs;
        }
    }

    const docRef = qs[0].ref;
    await deleteDoc(docRef);
}

//user to teacher conversion

// export async function addTeacher(name: string, accessLevel: string, mail: string, subjects: string[]) {
//     var teacher = new Teacher(
//         name,
//         accessLevel,
//         mail,
//         subjects
//     )

//     const res = await addDoc(collection(db, 'teachers'), Object.assign({}, teacher));

//     return res
// }

async function addAlert(author: string, importance: number, message: string, title: string, lessonandgrade?: string | undefined) {
  var alert = new Alert(author, importance, message, title, lessonandgrade)
  const res = await addDoc(collection(db, 'alerts'), Object.assign({}, alert))
  return res
}

async function getAlertsByImpAndLessonandgrade(imp: number, lessonandgrade?: string | undefined) {
    var alertsQuery: Query;
    try {
        
        const alertsCollectionRef = collection(db, "alerts");
        // Create a query that filters documents where the "importance" field is equal to 1
        if (lessonandgrade === "" || lessonandgrade === "mainpage" || lessonandgrade === undefined) {
            alertsQuery = query(alertsCollectionRef, where("importance", "==", imp));
        } else {
            alertsQuery = query(alertsCollectionRef, where("importance", "==", imp), where("lessonandgrade", "==", lessonandgrade));
        }
        
        const querySnapshot = await getDocs(alertsQuery);
        // Process the querySnapshot to get the actual data
        const alerts = querySnapshot.docs.map((doc) => doc.data());
        return alerts as Alert[];
    } catch (error) {
        console.log("Error fetching alerts:", error);
        return [];
    }
}

async function getAlerts() {
    try {
        const alertsCollectionRef = collection(db, "alerts");
        const alertSnapshot = await getDocs(alertsCollectionRef);
        // Process the querySnapshot to get the actual data
        const alerts = alertSnapshot.docs?.map((doc) => doc.data());
        return alerts;
    } catch (error) {
        console.error("Error fetching alerts:", error);
        return [];
    }
}

// async function addTopic(subject: Subject, grade: string, tname:string) {
//     try {
//         var topic = new Topic(
//             tname,
//             subject,
//             grade,
//             [],
//         )
//         await addDoc(collection(db, "topics/" + subject + "/" + grade), Object.assign({}, topic) )
//     }
//     catch {
//         return "bruh"
//     }
// }
// async function delTopic(subject: Subject, grade: string, tname:string) {
//     const collRef = collection(db, "topics/" + subject + "/" + grade )
//     const q = query(collRef, where("name" , "==" , tname))
//     const qs = (await getDocs(q)).docs;
//     if (qs.length !== 1) {
//         if (qs.length === 0) {
//             console.log("Error: Material not found.");
//             return 1;
//         } else {
//             console.log("Error: Multiple matching materials.");
//             return 1;
//         }
//     }
//     const docRef = qs[0].ref;
//     await deleteDoc(docRef);
// }

async function addTopic(subject: Subject, grade: string, tname: string, subtopics?: string[]) {
    try {
        const topicRef = collection(db, `topics/${subject}/subjects${grade}`);
        const newTopicDoc = await addDoc(topicRef, { tname, subtopics: subtopics ? subtopics : [] });
        return newTopicDoc.id; // Optionally, you can return the document ID if needed.
    } catch (error) {
        console.error("Error adding topic:", error);
        return null;
    }
}

async function delTopic(subject: Subject, grade: string, tname: string) {
    try {
        const collRef = collection(db, `topics/${subject}/subjects${grade}`);
        const q = query(collRef, where("tname", "==", tname));
        const qs = (await getDocs(q)).docs;

        if (qs.length === 1) {
            const docRef = qs[0].ref;
            await deleteDoc(docRef);
            return 0; // Success
        } else {
            return 1; // Error: Topic not found or multiple matching topics.
        }
    } catch (error) {
        console.error("Error deleting topic:", error);
        return 1;
    }
}

async function editTopic(subject: Subject, grade: string, pname: string, nname: string, newSubtopics: string[]) {
    try {
        // Delete the existing topic
        const delResult = await delTopic(subject, grade, pname);
        if (delResult === 0) {
            // Add the updated topic with new name and subtopics
            const newTopicId = await addTopic(subject, grade, nname, newSubtopics);
            return newTopicId;
        }
    } catch (error) {
        console.error("Error editing topic:", error);
        return null;
    }
}

async function addSubtopic(subject: Subject, grade: string, tname: string, subtopic: string) {
    try {
        const collRef = collection(db, `topics/${subject}/subjects${grade}`);
        const q = query(collRef, where("tname", "==", tname));
        const qs = (await getDocs(q)).docs;

        if (qs.length === 1) {
            const docRef = qs[0].ref;
            await updateDoc(docRef, {
                subtopics: [...qs[0].data().subtopics, subtopic],
            });
            return 0; // Success
        } else {
            return 1; // Error: Topic not found or multiple matching topics.
        }
    } catch (error) {
        console.error("Error adding subtopic:", error);
        return 1;
    }
}

//get topics based on subject and grade as document and subcollection names
export const getTopics = async (subject: Subject, grade: string) => {
    const collRef = collection(db, "topics/" + subject + "/" + grade )
    const q = query(collRef)
    const qs = (await getDocs(q)).docs;
    var topics: Topic[] = []
    qs.forEach( (doc: any)  => {
        topics.push(doc.data())
    });
    console.log("topics: ", topics)
    return topics as Topic[];
}


//for teacher creation, use the uid's as the if of the added document. Like so :

// Add a new document in collection "teachers" with "userUid" as id

// const a = async() => {
//      await setDoc(doc(db, "teachers", "userUid"), {
//          name: "Tuğrul Teacher",
//          mail: "a@gmail.com",
//          subjects: ["HiSTORY", "Math"],
//          accessLevel: "teacher"
//         });
// }

export const getUsers = async (uid?: string, name?: string, isAdmin?: boolean, mail?: string, subjects?: string[], grades?: string[], accessLevel?: string) => {
    const collRef = collection(db, "users")
    var q: Query;
    if (uid) {
        q = query(collRef, where("uid", "==", uid))
    } else if (name) {
        q = query(collRef, where("name", "==", name))
    } else if (isAdmin) {
        q = query(collRef, where("isAdmin", "==", isAdmin))
    } else if (mail) {
        q = query(collRef, where("mail", "==", mail))
    } else if (subjects) {
        q = query(collRef, where("subjects", "==", subjects))
    } else if (grades) {
        q = query(collRef, where("grades", "==", grades))
    } else if (accessLevel) {
        q = query(collRef, where("accessLevel", "==", accessLevel))
    } else {
        q = query(collRef)
    }
    const qs = (await getDocs(q)).docs;
    var users: AppUser[] = []
    qs.forEach( (doc: any)  => {
        users.push(doc.data())
    });
    return users as AppUser[];
}

export async function fetchFirstUserByUid(userUid: string) {
    const users = await getUsers(userUid);
    const firstUser = users[0];
    if (firstUser) {
        return firstUser as AppUser;
    }
}
export const updateUserAdminStatus = async (userUid: string, isAdmin: boolean) => {
    var appUser: AppUser | undefined= await fetchFirstUserByUid(userUid);
    var newUserData = {
        name: appUser?.name,
        mail: appUser?.mail,
        accesslevel: appUser?.accesslevel,
        isAdmin: isAdmin,
        creationTime: appUser?.creationTime
    }
    try {
        // and if loop to see if user exists. if it does, update. if not, do nothing.
        const docRef = doc(db, "users", userUid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            await updateDoc(docRef, newUserData).then(() => {
                console.log("User successfully updated in firestore")
                return "success"
            })
        } else {
            console.log("user does not exist in firestore (db.ts)")
            return "user does not exist"
        }
        
    }
    catch (error){
        console.log("(error) failed to update user to firestore: ", error)
        return "failed to update user"
    }
}

export const updateUserEmail = async (userUid: string, mail: string) => {
    var appUser: AppUser | undefined= await fetchFirstUserByUid(userUid);
    var newUserData = {
        name: appUser?.name,
        mail: mail,
        accesslevel: appUser?.accesslevel,
        isAdmin: appUser?.isAdmin,
        creationTime: appUser?.creationTime
    }
    try {
        // and if loop to see if user exists. if it does, update. if not, do nothing.
        const docRef = doc(db, "users", userUid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            await updateDoc(docRef, newUserData).then(() => {
                console.log("User successfully updated in firestore")
                return "success"
            })
        } else {
            console.log("user does not exist in firestore (db.ts)")
            return "user does not exist"
        }
        
    }
    catch (error){
        console.log("(error) failed to update user to firestore: ", error)
        return "failed to update user"
    }
}

export const updateUserSubjects = async (userUid: string, subjects: string[]) => {
    var appUser: AppUser | undefined= await fetchFirstUserByUid(userUid);
    var newUserData = {
        name: appUser?.name,
        mail: appUser?.mail,
        accesslevel: appUser?.accesslevel,
        isAdmin: appUser?.isAdmin,
        creationTime: appUser?.creationTime
    }
    try {
        // and if loop to see if user exists. if it does, update. if not, do nothing.
        const docRef = doc(db, "users", userUid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            await updateDoc(docRef, newUserData).then(() => {
                console.log("User successfully updated in firestore")
                return "success"
            })
        } else {
            console.log("user does not exist in firestore (db.ts)")
            return "user does not exist"
        }
        
    }
    catch (error){
        console.log("(error) failed to update user to firestore: ", error)
        return "failed to update user"
    }
}

export const updateUserGrades = async (userUid: string, grades: string[]) => {
    var appUser: AppUser | undefined= await fetchFirstUserByUid(userUid);
    var newUserData = {
        name: appUser?.name,
        mail: appUser?.mail,
        accesslevel: appUser?.accesslevel,
        isAdmin: appUser?.isAdmin,
        creationTime: appUser?.creationTime
    }
    try {
        // and if loop to see if user exists. if it does, update. if not, do nothing.
        const docRef = doc(db, "users", userUid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            await updateDoc(docRef, newUserData).then(() => {
                console.log("User successfully updated in firestore")
                return "success"
            })
        } else {
            console.log("user does not exist in firestore (db.ts)")
            return "user does not exist"
        }
        
    }
    catch (error){
        console.log("(error) failed to update user to firestore: ", error)
        return "failed to update user"
    }
}

export const updateUserAccessLevel = async (userUid: string, accessLevel: string) => {
    var appUser: AppUser | undefined= await fetchFirstUserByUid(userUid);
    var newUserData = {
        name: appUser?.name,
        mail: appUser?.mail,
        accesslevel: accessLevel,
        isAdmin: appUser?.isAdmin,
        creationTime: appUser?.creationTime
    }
    try {
        // and if loop to see if user exists. if it does, update. if not, do nothing.
        const docRef = doc(db, "users", userUid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            await updateDoc(docRef, newUserData).then(() => {
                console.log("User successfully updated in firestore")
                return "success"
            })
        } else {
            console.log("user does not exist in firestore (db.ts)")
            return "user does not exist"
        }
        
    }
    catch (error){
        console.log("(error) failed to update user to firestore: ", error)
        return "failed to update user"
    }
}
// export const updateUser = async (userUid: string, name?: string, mail?: string, accesslevel?: string, isAdmin?: boolean) => {
//     try {
//         var appuser = await getUsers(userUid)[0]
//         var teacheruser = new Teacher(
//             appuser,
//             [],
//             [],
//             [],
//             isAdmin?
//         )
//         var teacherData = {
//             name: teacheruser.name,
//             mail: teacheruser.mail,
//             accessLevel: teacheruser.accesslevel,
//             isAdmin: teacheruser.isAdmin,
//             grades: teacheruser.grades,
//             subjects: teacheruser.subjects,
//             materials: teacheruser.materials
//         }
//         // and if loop to see if user exists. if it does, update. if not, do nothing.
//         const docRef = doc(db, "users", userUid)
//         const docSnap = await getDoc(docRef)
//         if (docSnap.exists()) {
//             await updateDoc(docRef, teacherData).then(() => {
//                 console.log("User successfully updated in firestore")
//                 return "success"
//             })
//         } else {
//             console.log("user does not exist in firestore (db.ts)")
//             return "user does not exist"
//         }
        
//     }
//     catch (error){
//         console.log("(error) failed to update user to firestore: ", error)
//         return "failed to update user"
//     }
// }

export const addAppUserOnLogin = async (userUid: string, name: string, mail: string, accesslevel: string) => {
    // const o = Object.assign({userUid, }, AppUser)
    // await setDoc(doc(db, "users", userUid), {
    //     name: name,
    //     mail: mail,
    //     accessLevel: accessLevel
    // });

    try {
        var appuser = new AppUser(
            userUid,
            name,
            mail,
            accesslevel,
            false
        )
        // and if loop to see if user exists. if it does, do nothing. if not, add it.
        const docRef = doc(db, "users", userUid)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            console.log("user already exists in firestore (db.ts)")
            //user already exists, return user data.
            return (docSnap.data() as AppUser)
        }
        if (!docSnap.exists()) {
            await setDoc(doc(db, "users", userUid), Object.assign({}, appuser) ).then(() => {
                console.log("User successfully added/updated to firestore")
                
            })
            return appuser;
        }
        
    }
    catch (error){
        console.log("(error) failed to add user to firestore: ", error)
        return "failed to add user"
    }
}

export const getAppUser = async (userUid: string) => {
    const docRef = doc(db, "users", userUid)
    const docSnap = await getDoc(docRef)
    if (docSnap.exists()) {
        return docSnap.data() as AppUser
    } else {
        console.log("user does not exist in firestore (db.ts)")
        return null
    }
}

export const getSubjects = async (gradeNumberOrPrep: string) => {
    const gradeDocRef = doc(db, 'grades', 'subjects');
    const gradeDoc = await getDoc(gradeDocRef);
    var availableSubjects : string[] = [];
    if (gradeDoc.exists()) {
        const gradeData = gradeDoc.data();
        for (const [key] of Object.entries(gradeDoc.data()!)) {
            if (key.includes(gradeNumberOrPrep)) {
                if (gradeData && gradeData[key]) {
                    //push the subjects of the grade to the array if it doesnt exist already.
                    gradeData[key].forEach((subject: string) => {
                        if (!availableSubjects.includes(subject)) {
                            availableSubjects.push(subject);
                        }
                    });
                } else {
                    console.log('No subjects found for grade', gradeNumberOrPrep);
                }
            } else {
                // console.log('No subjects found for grade', gradeNumberOrPrep);
            }
        }
        return availableSubjects;
    } else {
        console.log('Document not found');
        return null
    }
}
export {addMaterial, getMaterials, getAlertsByImpAndLessonandgrade, getAlerts, addAlert, delAlert, delMaterial, editMaterial, addTopic, delTopic, editTopic, addSubtopic};


