import { UploadResult, getStorage, ref, uploadBytes, deleteObject, listAll} from "firebase/storage";
import { app } from "../configs/firebaseconfigs";
// Create a root reference
export const storage = getStorage(app);

//add a file picker to admin.tsx and select a file from there of whatever type, but primarily pdf and image types, and upload it to firebase storage with a unique name. Name can be automated to date and uploader uid combined, but preferably it should be given. but I just want to get the file upload working first.
export const uploadFile = async (file: File, name: string, location: string) => {
  const storageRef = ref(storage, location + "/" + name);
  try {
  await uploadBytes(storageRef, file).then((uploadedFile) => {
    console.log("File uploaded successfully", uploadedFile);
    /// return promise with the url of the uploaded file
    return uploadedFile as UploadResult
    })
    return 1
  } catch (error) {
    console.log("Error uploading file", error);
  }

};

export const deleteFile = async (name: string, location: string) => {
  const storageRef = ref(storage, location + "/" + name);
  await deleteObject(storageRef)
    .then(() => {
      console.log("File deleted successfully");
    })
    .catch((error) => {
      console.log("Error deleting file", error);
    });
}

export const recursiveDelete = async (location: string) => {
  //delete all files in a folder and in all subfolders of a folder.
  // const storageRef = ref(storage, location); //folder location
  const listRef = ref(storage, location);
  // Find all the prefixes and items.
  try {
    console.log("Storage: deleting files in folder", location)
    const result = await listAll(listRef);
    result.prefixes.forEach((folderRef) => {
      // All the prefixes under listRef.
      // You may call listAll() recursively on them.
      recursiveDelete(folderRef.fullPath);
    });
    result.items.forEach((itemRef) => {
      // All the items under listRef.
      deleteFile(itemRef.name, location);
    });
  } catch (error) {
    console.log("Error deleting file", error);
  }
  
}
